import { TSimpleStringObj } from '@models/index';
import { TTelegramAuthData } from '@models/Auth';

import { TGetRequestData } from './types';

export interface IStorageApi {
  setUserData: (userData: TTelegramAuthData) => void;
  setUserId: (userId: number) => void;
  getUserId: () => { userId: string | null };
  getUserData: () => { userData: TTelegramAuthData | null };
  setAuth: (status: boolean) => void;
  getAuth: () => { auth: boolean };
  setSelectedFilialData: ({
    accId,
    filialName,
    branchId,
  }: {
    accId: string;
    filialName: string;
    branchId: number;
  }) => void;
  getSelectedFilialData: () => {
    accId: string | null;
    filialName: string | null;
    branchId: number | null;
  };
  setToken: (token: string) => void;
  getToken: () => { token: string | null };
  getRequestData: () => TGetRequestData;
  setAuthData: ({
    auth,
    token,
    authUserData,
  }: {
    auth: boolean;
    token: string;
    authUserData: TTelegramAuthData;
  }) => void;
  clearDB: () => void;
}

/**
 * Class осуществляет работы с хранилищем браузера
 * может сохранять в localStorage и sessionStorage
 * объект хранилища передается при создании инстанса класса
 * @module StorageApi
 */

class StorageApi implements IStorageApi {
  private static _instance: StorageApi;

  private DB: Storage;

  private readonly Salt: string;

  private readonly Rights: string;

  private readonly AccId: string;

  private readonly BranchId: string;

  private readonly UserId: string;

  private readonly FilialName: string;

  private readonly Auth: string;

  private readonly Token: string;

  private readonly UserData: string;

  private readonly Emoji: string;

  private readonly AnalyticsStart: string;

  private readonly AnalyticsEnd: string;

  private readonly ShowTooltip: string;

  constructor(DB: Storage) {
    if (StorageApi._instance) {
      throw new Error("Singleton classes can't be instantiated more than once.");
    }
    this.DB = DB;
    this.Salt = 'salt';
    this.Auth = 'authAB';
    this.UserId = 'idAB';
    this.Emoji = 'emoji';
    this.AccId = 'accIdAB';
    this.Token = 'tokenAB';
    this.Rights = 'rights';
    this.BranchId = 'branchId';
    this.UserData = 'userDataAB';
    this.FilialName = 'filialNameAB';
    this.ShowTooltip = 'showTooltipAB';
    this.AnalyticsEnd = 'analyticsEndAB';
    this.AnalyticsStart = 'analyticsStartAB';
  }

  /**
   * Static method to get the singleton instance of the StorageApi class.
   * @static
   * @param {Storage} DB - The browser storage object (localStorage or sessionStorage).
   * @returns {StorageApi} The singleton instance of the StorageApi class.
   */
  public static getInstance(DB: Storage): StorageApi {
    if (!StorageApi._instance) {
      StorageApi._instance = new StorageApi(DB);
      Object.freeze(StorageApi._instance);
    }
    return StorageApi._instance;
  }

  private getItemFromDB(itemName: string): string {
    return this.DB.getItem(itemName) || '';
  }

  private setItemToDB({ itemName, value }: { itemName: string; value: string }): void {
    this.DB.setItem(itemName, value);
  }

  /**
   * Возвращает из хранилища данные о авторизации
   * @method getAuth
   * @return {string} {auth: string}
   */
  getAuth() {
    const auth = this.getItemFromDB(this.Auth) === 'true';
    return { auth };
  }

  /**
   * Возвращает из хранилища данные выбранном филиале
   * @method getSelectedFilialData
   * @return {string | null} {accId: string | null, filialName: string | null}
   */
  getSelectedFilialData() {
    const accId = this.getItemFromDB(this.AccId);
    const branchId = +this.getItemFromDB(this.BranchId);
    const filialName = this.getItemFromDB(this.FilialName);
    return { accId, branchId, filialName };
  }

  /**
   * Возвращает из хранилища токен
   * @method getToken
   * @return {string} {token: string}
   */
  getToken() {
    const token = this.getItemFromDB(this.Token);
    return { token };
  }

  /**
   * Возвращает из хранилища данные авторизованного пользователя
   * @method getUserData
   * @return {TTelegramAuthData} {userData: ITelegramAuthData}
   */
  getUserData() {
    const userData = this.getItemFromDB(this.UserData);
    if (userData) {
      try {
        return { userData: JSON.parse(userData) as TTelegramAuthData };
      } catch (error) {
        return { userData: null };
      }
    }
    return { userData: null };
  }

  /**
   * Сохраняет в хранилище данные авторизации
   * @method setAuth
   * @param status {boolean}
   */
  setAuth(status: boolean) {
    this.setItemToDB({ itemName: this.Auth, value: JSON.stringify(status) });
  }

  /**
   * Сохраняет в хранилище данные выбранного филиала
   * @method setSelectedFilialData
   * @param accId {string} id филиала
   * @param filialName {string} название филиала
   * @param branchId {number} id филиала
   */
  setSelectedFilialData({
    accId,
    filialName,
    branchId,
  }: {
    accId: string;
    filialName: string;
    branchId: number;
  }): void {
    this.setItemToDB({ itemName: this.AccId, value: accId });
    this.setItemToDB({ itemName: this.BranchId, value: String(branchId) });
    this.setItemToDB({ itemName: this.FilialName, value: filialName });
  }

  /**
   * Сохраняет в хранилище токен
   * @method setToken
   * @param token {string} токен авторизации запросов
   */
  setToken(token: string): void {
    this.setItemToDB({ itemName: this.Token, value: token });
  }

  /**
   * Сохраняет в хранилище telegram ID авторизованного пользователя
   * @method setUserId
   * @param userId {number}
   */
  setUserId(userId: number) {
    this.setItemToDB({ itemName: this.UserId, value: JSON.stringify(userId) });
  }

  /**
   * Возвращает из хранилища telegram ID авторизованного пользователя
   * @method getUserId
   * @return {string} {userId: string}
   */
  getUserId() {
    const userId = this.getItemFromDB(this.UserId);
    return { userId };
  }

  /**
   * Сохраняет в хранилище данные авторизованного пользователя
   * @method setUserData
   * @param userData {TTelegramAuthData}
   * @return {void}
   */
  setUserData(userData: TTelegramAuthData): void {
    const data = JSON.stringify(userData);
    this.setItemToDB({ itemName: this.UserData, value: data });
    this.setItemToDB({ itemName: this.UserId, value: String(userData.id) });
  }

  /**
   * Возвращает из хранилища данные для осуществления запросов к API
   * @method getRequestData
   * @return {number | string} {userId: number, accId: string, accessToken: string, branchId: string}
   */
  getRequestData() {
    const { token } = this.getToken();
    const userId = Number(this.getUserId().userId);
    const { accId, branchId } = this.getSelectedFilialData();

    return {
      userId: userId || 0,
      accId: accId || '',
      accessToken: token || '',
      branchId: branchId || 0,
    };
  }

  /**
   * Сохраняет в хранилище все данные авторизации
   * @method setAuthData
   * @param userData {TTelegramAuthData}
   * @param token {string}
   * @param auth {boolean}
   * @return {void}
   */
  setAuthData({
    auth,
    token,
    authUserData,
  }: {
    auth: boolean;
    token: string;
    authUserData: TTelegramAuthData;
  }): void {
    this.setAuth(auth);
    this.setToken(token);
    this.setUserData(authUserData);
  }

  getEmoji() {
    const emoji = this.getItemFromDB(this.Emoji);
    try {
      return { emoji: JSON.parse(emoji) };
    } catch (error) {
      return { emoji: {} };
    }
  }

  /**
   * Сохраняет в хранилище  список последних эмодзи
   * @method setEmoji
   * @param emoji {TSimpleStringObj}
   */
  setEmoji(emoji: TSimpleStringObj) {
    this.setItemToDB({ itemName: this.Emoji, value: JSON.stringify(emoji) });
  }

  /**
   * Сохраняет в хранилище  дату начала аналитики
   * @method setAnalyticsStart
   * @param start {string}
   */
  setAnalyticsStart(start: string) {
    this.setItemToDB({ itemName: this.AnalyticsStart, value: start });
  }

  /**
   * Сохраняет в хранилище  дату окончания аналитики
   * @method setAnalyticsEnd
   * @param end {string}
   */
  setAnalyticsEnd(end: string) {
    this.setItemToDB({ itemName: this.AnalyticsEnd, value: end });
  }

  /**
   * Возвращает из хранилища дату начала аналитики
   * @method getAnalyticsStart
   * @return {string} {start: string}
   */
  getAnalyticsStart() {
    return this.getItemFromDB(this.AnalyticsStart);
  }

  /*
   * Возвращает из хранилища дату окончания аналитики
   * @method getAnalyticsEnd
   * @return {string} {end: string}
   */
  getAnalyticsEnd() {
    return this.getItemFromDB(this.AnalyticsEnd);
  }

  /**
   * Возвращает из хранилища статус ознакомительной подсказки
   * @method getTooltipStatus
   * @return {tooltip: string}
   */
  getTooltipStatus() {
    const tooltip = this.getItemFromDB(this.ShowTooltip);
    return { tooltip };
  }

  /**
   * Сохраняет в хранилище статус ознакомительной подсказки
   * @method setTooltipStatus
   * @param tooltip {string}
   */
  setTooltipStatus(tooltip: string) {
    this.setItemToDB({ itemName: this.ShowTooltip, value: tooltip });
  }

  private crypt(textToCrypt: string) {
    const textToChars = (text: string) => text.split('').map(c => c.charCodeAt(0));
    const byteHex = (n: number) => `0${Number(n).toString(16)}`.substr(-2);
    const applySaltToChar = ([code]: number[]) =>
      textToChars(this.Salt).reduce((a, b) => a ^ b, code);

    return textToCrypt.split('').map(textToChars).map(applySaltToChar).map(byteHex).join('');
  }

  private decrypt(encoded: string): string {
    const textToChars = (text: string) => text.split('').map(c => c.charCodeAt(0));
    const applySaltToChar = (code: number) => textToChars(this.Salt).reduce((a, b) => a ^ b, code);
    const matchArray = encoded.match(/.{1,2}/g) || [];
    return matchArray
      .map(hex => parseInt(hex, 16))
      .map(applySaltToChar)
      .map(charCode => String.fromCharCode(charCode))
      .join('');
  }

  setRight(rights: string[]) {
    const cryptRights = this.crypt(JSON.stringify(rights));
    this.setItemToDB({ itemName: this.Rights, value: cryptRights });
  }

  getRights(): string[] {
    const cryptRights = this.getItemFromDB(this.Rights);
    if (cryptRights) {
      let rights: string[] = [];
      try {
        rights = JSON.parse(this.decrypt(cryptRights));
        return rights;
      } catch (e) {
        // console.error(e);
      }
    }
    return [];
  }

  /**
   * Очищает данные в базе
   * @method clearDB
   */
  clearDB() {
    this.DB.clear();
  }
}

export const storageDb = StorageApi.getInstance(localStorage);

Object.freeze(storageDb);
