import React, { memo, useCallback, useMemo } from 'react';
//
import { THelpKeys } from '@models/index';
import { useEmoji } from '@hooks/useEmoji';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { TextAreaWithLabel } from '@blocks/textAreaWithLabel';
import { TEPNameList, TOnChangeHandler, TOnChangeHandlerInputTextarea } from '@shared/types';

interface ITaskBlockProps {
  /**
   * Название задачи
   * @param {string}
   */
  taskName: string;
  /**
   * Флаг отключает возможность ввода значений в поля
   * @param {boolean}
   */
  disabled: boolean;
  /**
   * Описание задачи
   * @param {string}
   */
  taskDescription: string;
  /**
   * OnChange обработчик для input с названием задачи
   * @param {TOnChangeHandler}
   */
  setTaskNameHandler: TOnChangeHandler;
  /**
   * OnChange обработчик для textarea с описанием задачи
   * @param {TOnChangeHandlerInputTextarea}
   */
  setTaskDescriptionHandler: TOnChangeHandlerInputTextarea;
  /**
   * Значение параметра name для input, необходимо для OnChangeHandlers
   * @param {string}
   */
  templateInputName: string;
  /**
   * Значение параметра name для textarea, необходимо для OnChangeHandlers
   * @param {THelpKeys}
   */
  templateDescription: THelpKeys;
  /**
   * Placeholder для элемента input
   * @param {string}
   */
  templateInputPlaceholder: string;
  /**
   * Placeholder для элемента textarea
   * @param {string}
   */
  templateTextareaPlaceholder: string;
  /**
   * Устанавливает поле название обязательным
   * @param {boolean}
   */
  nameIsRequired: boolean;
  /**
   * Устанавливает поле описание обязательным
   * @param {boolean}
   */
  descriptionIsRequired: boolean;
  /**
   * Устанавливает максимальное количество символов для textarea
   * @param {number}
   */
  textareaValueMaxLength: number;
  /**
   * Опциональный параметр callback вызывается при событии onBlur на элементе textarea
   * @param {(event: React.FocusEvent<HTMLTextAreaElement>) => void}
   */
  onTextareaBlurHandler?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  /**
   * Параметр отвечающий за определиние типа страницы
   * @param {TEPNameList}
   */
  type: TEPNameList;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const TaskDescription = memo(
  ({
    type,
    disabled,
    taskName,
    nameIsRequired,
    taskDescription,
    templateInputName,
    setTaskNameHandler,
    templateDescription,
    onTextareaBlurHandler,
    descriptionIsRequired,
    textareaValueMaxLength,
    templateInputPlaceholder,
    setTaskDescriptionHandler,
    templateTextareaPlaceholder,
    className = '',
  }: ITaskBlockProps) => {
    const { setEmojiInTextarea, setTextAreaOnBlur, setViewEmojiPicker } = useEmoji({ type });

    const onBlurHandler = useCallback(
      (event: React.FocusEvent<HTMLTextAreaElement>) => {
        setTextAreaOnBlur(event);
        if (onTextareaBlurHandler) {
          onTextareaBlurHandler(event);
        }
      },
      [onTextareaBlurHandler, setTextAreaOnBlur],
    );

    const memoizedTextInput = useMemo(
      () => (
        <TextInput
          fill
          type='text'
          view='outlined'
          value={taskName}
          disabled={disabled}
          id={templateInputName}
          name={templateInputName}
          onChange={setTaskNameHandler}
          onBlur={onTextareaBlurHandler}
          placeholder={templateInputPlaceholder}
          error={nameIsRequired && !taskName && !disabled}
          color={nameIsRequired && !taskName && !disabled ? 'danger' : 'default'}
        />
      ),
      [
        taskName,
        disabled,
        nameIsRequired,
        templateInputName,
        setTaskNameHandler,
        onTextareaBlurHandler,
        templateInputPlaceholder,
      ],
    );

    const memoizedTextarea = useMemo(
      () => (
        <TextAreaWithLabel
          isEmoji
          maxLength={textareaValueMaxLength || 2000}
          disabled={disabled}
          value={taskDescription}
          isError={!taskDescription}
          htmlFor={templateDescription}
          onBlurHandler={onBlurHandler}
          isRequired={descriptionIsRequired}
          setEmojiInTextarea={setEmojiInTextarea}
          placeholder={templateTextareaPlaceholder}
          onChangeHandler={setTaskDescriptionHandler}
          setViewEmojiPicker={setViewEmojiPicker}
          showInfo={false}
        />
      ),
      [
        disabled,
        onBlurHandler,
        taskDescription,
        setViewEmojiPicker,
        setEmojiInTextarea,
        templateDescription,
        descriptionIsRequired,
        textareaValueMaxLength,
        setTaskDescriptionHandler,
        templateTextareaPlaceholder,
      ],
    );

    return (
      <div className={className}>
        <div className='mb-4'>
          <p className='text-black text-h3Mobile mb-4'>Задача</p>
          <p className='text-grayText mb-1 tracking-[0.0275em]'>
            Название {nameIsRequired ? <span className='text-red'>*</span> : null}
          </p>
          {memoizedTextInput}
        </div>
        <div className=''>{memoizedTextarea}</div>
      </div>
    );
  },
);
