import React, { useEffect } from 'react';
import { useNavigate, useLocation, Routes, createSearchParams } from 'react-router-dom';
//
import { logout } from '@redux/Auth/slice';
import { storageDb } from '@api/storageApi';
import { ACCESS_RIGHTS } from '@const/access';
import { PATH_NAMES } from '@const/httpConst';
import { QUERY_PARAMS } from '@const/apiConstants';
import { amplitudeSetUserId } from '@helpers/amplitude';
import { updateToken } from '@redux/Auth/thunk';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { getAccountsData, getAuthData } from '@redux/selectors';
import { setSelectedFilial } from '@redux/Accounts/slice';

export interface IPrivateRouteProps {
  children: React.ReactNode;
}

export const PrivateRoute = ({ children }: IPrivateRouteProps) => {
  const dispatch = useAppDispatch();
  // получаем объект navigate для организации редиректов
  const navigate = useNavigate();

  // Получаем массив прав если он есть и проверяем наличие необходимых прав
  const rights = storageDb.getRights();

  // получаем текущий путь
  const path = useLocation().pathname;

  const { selectedFilial, data } = useAppSelector(getAccountsData);

  // получаем из LS статус авторизации auth
  const { auth } = storageDb.getAuth();

  // получаем из LS id выбранного ранее филиала
  const { accId } = storageDb.getSelectedFilialData();

  // получаем из LS данные авторизованного пользователя
  const { userData } = storageDb.getUserData();

  // Получаем из LS авторизационный токен пользователя для последующего обновления
  const { token } = storageDb.getToken();

  // получаем из LS статус авторизации auth
  const { auth: isAuth } = storageDb.getAuth();

  // получаем состояние запроса токена авторизации
  const { isUpdTokenSuccess, isUpdTokenError, isUpdTokenPending } = useAppSelector(getAuthData);

  // Эффект переписывает данне о выбранном филиале в LS и обновляет токен при возвращении фокуса на вкладку
  useEffect(() => {
    const setSelectFilialData = () => {
      if (document.visibilityState === 'visible' && auth && path !== PATH_NAMES.START_PAGE) {
        if (token && auth) {
          dispatch(updateToken(token));
          storageDb.setSelectedFilialData({
            accId: selectedFilial?.accId || '',
            filialName: selectedFilial?.name || '',
            branchId: selectedFilial?.branchId || 0,
          });
        }
      }
    };
    document.addEventListener('visibilitychange', setSelectFilialData);

    return () => {
      document.removeEventListener('visibilitychange', setSelectFilialData);
    };
  });

  // Эффект проверяет наличие флага авторизации, в случае его отсутствия происходит редирект на страницу авторизации
  useEffect(() => {
    if ((!userData || !auth) && path !== PATH_NAMES.START_PAGE) {
      dispatch(logout());
      navigate(PATH_NAMES.START_PAGE);
    }
  });

  // Эффект сделан для обработки прямых ссылок сохраненных или полученных от саппорта,
  // проверяется наличие авторизации и id выбранного ранее филиала.
  // Если филиал не выбран, редирект на страницу со списком филиалов.
  useEffect(() => {
    if (!accId && auth && path !== PATH_NAMES.ACCOUNTS && path !== PATH_NAMES.START_PAGE) {
      navigate(PATH_NAMES.ACCOUNTS);
    }
  });

  // При переходе на страницу авторизации эффект проверяет ести ли у нас авторизационные данные пользователя (авторизовывался ранее)
  // Если есть обновляем токен и переходим на страницу со списком филиалов
  useEffect(() => {
    if (auth && token && path === PATH_NAMES.START_PAGE) {
      if (!isUpdTokenPending && !isUpdTokenError && !isUpdTokenSuccess) {
        dispatch(updateToken(token));
      }

      if (isUpdTokenSuccess) navigate(PATH_NAMES.ACCOUNTS);
    }
  }, [isUpdTokenPending, isUpdTokenError, isUpdTokenSuccess]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    // Проверяем наличие прав на пользование настройками
    const isShowSettings = rights.includes(ACCESS_RIGHTS.SETTINGS);

    // Проверяем наличие прав на пользование страницей Админы
    const isShowAdminsInSettings = rights.includes(ACCESS_RIGHTS.SETTINGS_ADMINS);

    // Проверяем наличие прав на пользование аналитикой
    const isShowAnalytics = rights?.includes(ACCESS_RIGHTS.ANALYTICS);

    // Проверяем наличие прав на пользование шаблонами отчетов
    const isShowTemplates = rights?.includes(ACCESS_RIGHTS.TEMPLATES);

    if (
      (!isShowAnalytics && path === PATH_NAMES.DASHBOARD) ||
      (!isShowSettings && path === PATH_NAMES.SETTINGS) ||
      (!isShowTemplates && path === PATH_NAMES.REPORTS) ||
      (!isShowTemplates && path === PATH_NAMES.ONE_REPORTS_TEMPLATE) ||
      (!isShowTemplates && path === PATH_NAMES.NEW_REPORT_TEMPLATE) ||
      (!isShowAdminsInSettings && path === PATH_NAMES.SETTINGS_ADMINS)
    ) {
      navigate(PATH_NAMES.ACCOUNTS);
    }
  }, [path, rights, navigate]);

  // Эффект осуществляет переход на страницу всех шаблонов если у пользователя 1 филиал
  useEffect(() => {
    if (
      isAuth &&
      data.length === 1 &&
      (path === PATH_NAMES.ACCOUNTS || path === PATH_NAMES.START_PAGE)
    ) {
      const { accId: filialId, name, rights: filialRight, branchId } = data[0];
      dispatch(
        setSelectedFilial({
          accId: filialId,
          filialName: name,
          branchId,
          rights: filialRight,
        }),
      );
      amplitudeSetUserId(String(branchId));

      navigate({
        pathname: PATH_NAMES.TASKS_ALL,
        search: createSearchParams({
          [QUERY_PARAMS.ACC_ID]: filialId,
        }).toString(),
      });
    }
  }, [isAuth, accId, data, navigate, path, dispatch]);

  return <Routes>{children}</Routes>;
};
