import * as amplitude from '@amplitude/analytics-browser';

export type TAmplitudeEventData = {
  eventName: string;
  eventProperties: {
    interface: string;
    template_id?: string;
  };
};

export enum AMPLITUDE_CUSTOM_EVENTS {
  TEMPLATE_UPDATE = 'template_update',
  TEMPLATE_CREATE = 'template_create',
  LK_LOGIN = 'lk_login',
}

export const AMPLITUDE_INTERFACE = 'lk';

const aipKey = process.env.REACT_APP_AMPLITUDE_API_KEY || '';

export const amplitudeInit = () =>
  amplitude.init(aipKey, undefined, {
    serverZone: 'EU',
    defaultTracking: {
      pageViews: false,
    },
  });

export const amplitudeTrack = (eventProperties: TAmplitudeEventData) => {
  amplitude.track(eventProperties.eventName, {
    ...eventProperties.eventProperties,
    interface: AMPLITUDE_INTERFACE,
  });
};

export const amplitudeSetUserId = (branchId: string) => {
  const correctBranchId = branchId.length < 5 ? branchId.padStart(8, '0') : branchId;

  amplitude.setUserId(correctBranchId);
};
