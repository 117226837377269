import React, { lazy, memo, Suspense, useEffect } from 'react';
import { Route, useNavigate, useSearchParams } from 'react-router-dom';
//
import { storageDb } from '@api/storageApi';
import { NO_FILIAL_ERROR } from '@data/dict';
import { PATH_NAMES } from '@const/httpConst';
import { setAuthUserData } from '@redux/Auth/slice';
import { PrivateRoute } from '@components/privedRoute';
import { amplitudeSetUserId } from '@helpers/amplitude';
import withErrorBoundary from '@components/ErrorBoundary';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { getAccountsData, getAuthData } from '@redux/selectors';
// eslint-disable-next-line
import { setSelectedFilial, showNoFilialError } from '@redux/Accounts/slice';

// НЕ МЕНЯТЬ ПОРЯДОК ПОДКЛЮЧЕНИЯ СТИЛЕЙ!!!!!!!!!!!!!!!!!!
import 'normalize.css/normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';
import '@blueprintjs/datetime/lib/css/blueprint-datetime.css';
import '@setproduct-ui/styles/setproduct.css';

import './App.css';

import LoginPage from '@pages/login/index';
import AccountsPage from '@pages/accounts/index';
import DashboardPage from '@pages/dashboard';
import FinanceTablePage from '@pages/financeTable';
import MasterTablePage from '@pages/masterTable';
import AdminsTablePage from '@pages/adminsTable';
import TasksListPage from '@pages/tasksList/index';
import TaskPage from '@pages/task';
import ReportsListPage from '@pages/reportsList/index';
import ReportPage from '@pages/report';
import SettingsPage from '@pages/settings';
import PayPage from '@pages/pay/index';
import Page404 from '@pages/404/index';
import { Loader } from '@blocks/loader';
import { BaseTemplate } from '@templates/base';

const AnalyticsPage = lazy(() => import('@pages/analytics'));

/**
 * Компонент App
 * содержит роуты на страницы приложения
 * @module App
 */

const AppComponent = memo(() => {
  const isDev = BUILD_MODE === 'development';
  /**
   * Результат выполнения хука useNavigate()
   * метод для изменения страниц в приложении
   * @const
   * @type {NavigateFunction}
   */
  const navigate = useNavigate();
  /**
   * Объект dispatch для запуска экшинов
   * @const
   * @type { ThunkDispatch}
   */
  const dispatch = useAppDispatch();
  /**
   * Получаем id филиала из searchParams
   * @const
   */
  const [searchParams] = useSearchParams();
  const accId = searchParams.get('acc_id');
  /**
   * Получаем из localStorage данные пользователя
   * @const
   * @type {ITelegramAuthData}
   */
  const { userData } = storageDb.getUserData();
  /**
   * С помощью хука useAppSelector
   * получаем выбранный филиал и список аккаунтов
   * @const
   */
  const { selectedFilial, data: accounts } = useAppSelector(getAccountsData);
  /**
   * С помощью хука useAppSelector
   * получаем данные авторизованного пользователя
   */
  const { authUserData } = useAppSelector(getAuthData);
  /**
   * Получаем из localStorage id и имя филиала
   * @const
   */
  const { accId: accIdLS, filialName: filialNameLS } = storageDb.getSelectedFilialData();

  /**
   * Эффект проверяет полученные accIdLS и filialNameLS на содержание в них
   * "noFilial" ошибка которая записывается в LS при не успешной попытки найти в списке филиалов
   * accId филиала переданного в search параметрах
   * если ошибка есть диспатчится экшен записывающий ошибку в store
   * @function useEffect1
   */
  useEffect(() => {
    if (
      accIdLS &&
      accIdLS === NO_FILIAL_ERROR.TEXT &&
      filialNameLS &&
      filialNameLS === NO_FILIAL_ERROR.TEXT
    ) {
      dispatch(showNoFilialError());
    }
  }, [accIdLS, filialNameLS, accId, dispatch]);

  /**
   * При наличии данных в userData и отсутствии данных в store, записывает их в store
   * @function useEffect2
   */
  useEffect(() => {
    if (userData && !authUserData.id) {
      dispatch(setAuthUserData(userData));
    }
  }, [userData, authUserData.id, dispatch]);

  /**
   * Эффект сравнивает accId полученный из search параметра и сохраненный id выбранного филиала
   * если ID не равны в списке филиалов пытается найти филиал если филиал найден диспатчится экшен
   * который записывает новый филиал в выбранный филиал в store
   * если филиал не найден в store в поля accId и filialName записывается текст ошибки "noFilial"
   * @function useEffect3
   */
  useEffect(() => {
    if (accId && selectedFilial && accId !== selectedFilial.accId) {
      const [filial] = accounts.filter(item => item.accId === accId);
      if (filial) {
        dispatch(
          setSelectedFilial({
            accId,
            branchId: filial.branchId,
            filialName: filial.name,
            rights: filial.rights,
          }),
        );
        amplitudeSetUserId(String(filial.branchId));
      } else {
        dispatch(
          setSelectedFilial({
            accId: NO_FILIAL_ERROR.TEXT,
            branchId: 0,
            filialName: NO_FILIAL_ERROR.TEXT,
            rights: [],
          }),
        );
        navigate(PATH_NAMES.ACCOUNTS);
      }
    }
  }, [accId, selectedFilial, accounts, dispatch, navigate]);

  return (
    <div className='mx-auto max-w-[104.5rem]'>
      {isDev ? (
        <div className='bg-[#ffe5b3] rounded-lg !p-0 z-20 fixed left-0 bottom-0 h-6 w-full'>
          <div className='text-sm text-center'>Тестовая среда</div>
        </div>
      ) : null}
      <BaseTemplate isAccessDenied={false}>
        <Suspense fallback={<Loader className='h-full' />}>
          <PrivateRoute>
            <Route path={PATH_NAMES.START_PAGE} element={<LoginPage />} />
            <Route path={PATH_NAMES.ACCOUNTS} element={<AccountsPage />} />
            {/* Аналитика  */}
            <Route path={PATH_NAMES.ANALYTICS} element={<AnalyticsPage />} />
            <Route path={PATH_NAMES.ANALYTICS_ADMINS} element={<AnalyticsPage />} />
            {/* Дашборд  */}
            <Route
              path={PATH_NAMES.DASHBOARD}
              element={<DashboardPage path={PATH_NAMES.DASHBOARD} />}
            />
            <Route
              path={PATH_NAMES.FINANCES}
              element={<DashboardPage path={PATH_NAMES.FINANCES} />}
            />
            <Route path={PATH_NAMES.FINANCES_TABLE} element={<FinanceTablePage />} />
            <Route
              path={PATH_NAMES.MASTERS}
              element={<DashboardPage path={PATH_NAMES.MASTERS} />}
            />
            <Route path={PATH_NAMES.MASTERS_TABLE} element={<MasterTablePage />} />
            <Route path={PATH_NAMES.ADMINS} element={<DashboardPage path={PATH_NAMES.ADMINS} />} />
            <Route path={PATH_NAMES.ADMINS_TABLE} element={<AdminsTablePage />} />
            <Route
              path={PATH_NAMES.TASKS_ALL}
              element={<TasksListPage isRt={false} isEt={false} isCt={false} />}
            />
            <Route
              path={PATH_NAMES.TASKS_REGULAR}
              element={<TasksListPage isRt isEt={false} isCt={false} />}
            />
            <Route
              path={PATH_NAMES.TASKS_EVENT}
              element={<TasksListPage isRt={false} isEt isCt={false} />}
            />
            <Route
              path={PATH_NAMES.TASKS_CLIENTS}
              element={<TasksListPage isRt={false} isEt={false} isCt />}
            />
            <Route path={PATH_NAMES.ONE_TASK} element={<TaskPage />} />
            <Route path={PATH_NAMES.NEW_TASK} element={<TaskPage />} />
            <Route path={PATH_NAMES.REPORTS} element={<ReportsListPage />} />
            <Route path={PATH_NAMES.ONE_REPORTS_TEMPLATE} element={<ReportPage />} />
            <Route path={PATH_NAMES.NEW_REPORT_TEMPLATE} element={<ReportPage />} />
            {/* Настройки */}
            <Route path={PATH_NAMES.SETTINGS} element={<SettingsPage />} />
            <Route path={PATH_NAMES.TELEPHONY} element={<SettingsPage />} />
            <Route path={PATH_NAMES.SETTINGS_ADMINS} element={<SettingsPage />} />
            <Route path={PATH_NAMES.SETTINGS_NOTIFICATIONS} element={<SettingsPage />} />

            {/* TODO: Отключено по задаче AB-283 */}

            {/* <Route path={PATH_NAMES.SETTINGS_MASTERS} element={<SettingsPage />} /> */}

            <Route path={PATH_NAMES.SETTINGS_PAY} element={<SettingsPage />} />
            <Route path={PATH_NAMES.BLACKLIST} element={<SettingsPage />} />
            <Route path={PATH_NAMES.STAFF} element={<SettingsPage />} />
            <Route path={PATH_NAMES.PAY} element={<PayPage />} />
            <Route path={PATH_NAMES.PAGE_404} element={<Page404 />} />
          </PrivateRoute>
        </Suspense>
      </BaseTemplate>
    </div>
  );
});

const App = withErrorBoundary(AppComponent);

export default App;
