import { createAsyncThunk } from '@reduxjs/toolkit';
import { log, validatePhoneNumber } from '@helpers/index';
import { blackListApi } from '@api/blackList/blackListApi';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { BlackListData, TBlackListData } from '@models/BlackList';
import { TRequestStatus, REJECT_RESPONSE_KEY, BLACKLIST_RESPONSE_KEYS } from '@api/types';

export const getBlackList = createAsyncThunk<
  TBlackListData[] | void,
  void,
  { rejectValue: string }
>('blackList/getBlackList', (data, { dispatch, rejectWithValue }) => {
  return blackListApi
    .getBlackList()
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }
      if (BLACKLIST_RESPONSE_KEYS.DATA in response.data) {
        const parsedData = BlackListData.array().safeParse(response.data.data);

        if (!parsedData.success) {
          log('@@blackList/getBlackList pars error', parsedData.error.format());
          // throw new Error(parsedData.error.toString());
        } else {
          return parsedData.data;
        }
      }
    })
    .catch(error => {
      if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(getBlackList()), 100);
        return rejectWithValue(String(error.response.data.detail));
      }
      return rejectWithValue(String(error.response.status));
    });
});

export const addNumberToBlacklist = createAsyncThunk<
  number | undefined,
  { number: string },
  { rejectValue: string }
>('blackList/addNumberToBlacklist', (data, { dispatch, rejectWithValue }) => {
  if (!data.number) return;
  return blackListApi
    .addNumberToBlacklist({ phone: validatePhoneNumber(data.number) })
    .then(response => {
      return response.status;
    })
    .catch(error => {
      if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(addNumberToBlacklist({ number: data.number })), 100);
        return rejectWithValue(String(error.response.data.detail));
      }
      return rejectWithValue(String(error.response.status));
    });
});

export const deleteNumberFromBlacklist = createAsyncThunk<
  TRequestStatus | undefined,
  string,
  { rejectValue: string }
>('blackList/deleteNumberFromBlacklist', (newNumber: string, { dispatch, rejectWithValue }) => {
  return blackListApi
    .deleteNumberToBlacklist({ phone: newNumber })
    .then(response => {
      return response.data;
    })
    .catch(error => {
      if (error.response?.data?.detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED) {
        setTimeout(() => dispatch(deleteNumberFromBlacklist(newNumber)), 100);
        return rejectWithValue(String(error.response.data.detail));
      }

      return rejectWithValue(String(error.response.status));
    });
});
