/**
 * Энам с данными для кнопок текста и линков в хэдере и футере
 * @enum CONFIG
 */
export enum CONFIG {
  BB_LANDING_PAGE_LINK = 'https://admin-bot.ru/',
  BB_INTEGRATION_LINK = 'https://xn--90aauzrd9dq.xn--p1ai/',
  LINK_TO_KNOWLEDGE_BASE = 'http://beautybot.platrum.ru/lk',
  SUPPORT_TELEGRAM_LINK = 'https://t.me/supp_admin_bot',
  SUPPORT_PHONE_NUMBER_TO_HREF = '+74953083482',
  SUPPORT_PHONE_NUMBER_TO_SHOW = '+7 (495) 308-34-82',
  TASK_DESCRIPTION_MAX_LENGTH = '500',
  REPORT_DESCRIPTION_MAX_LENGTH = '1000',
  BEAUTY_BOT_LINK = 'https://t.me/beauty_whatsapp_bot?start=showId',
  PIN_BOT_LINK = 'https://t.me/salon_adminbot?start=showId',
}
