import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
//
import { Menu } from '@uikit/Menu';
import { Chips } from '@uikit/Chips';
import { Button } from '@uikit/Button';
import { Dialog } from '@uikit/Dialog';
import { Icon } from '@blueprintjs/core';
import { Dropdown } from '@uikit/Dropdown';
import { storageDb } from '@api/storageApi';
import { TFilialData } from '@models/Accounts';
import { TaskCopyCheckbox } from '@blocks/taskCopyCheckbox';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { getAccountsData, getTaskPageData } from '@redux/selectors';
import { clearFilialsToCopy, setFilialsToCopy } from '@redux/Task/slice';

type TTaskCopyProps = {
  /**
   * Флаг, указывающий, должен ли компонент иметь компактный вид.
   * @param {boolean}
   * @default false
   */
  dense?: boolean;

  /**
   * Хэндлер для сохранения шаблона
   * @param {() => void}
   */
  saveTemplateHandler: () => void;
  /**
   * Опциональный параметр отключает кнопку.
   * @param {boolean}
   * @default false
   */
  disabled?: boolean;
  /**
   * CSS-класс компонента.
   * @param {string}
   */
  className?: string;
};

export const TaskCopy = memo(
  ({ className = '', saveTemplateHandler, dense = false, disabled = false }: TTaskCopyProps) => {
    const dispatch = useAppDispatch();
    const { data: accounts } = useAppSelector(getAccountsData);
    const { accId: accIdLS } = storageDb.getSelectedFilialData();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const {
      filialsToCopy,
      isCopyTasksError,
      rejectedFilialsToCopy,
      isCopyTasksLoading,
      isCopyTasksSuccess,
    } = useAppSelector(getTaskPageData);

    /**
     * Обработчик закрытия модального окна.
     */
    const handleCloseModal = useCallback(() => {
      setIsModalOpen(false);
      dispatch(clearFilialsToCopy());
    }, [dispatch]);

    /**
     * Эффект закрывает модальное окно при успешном сохранении.
     */
    useEffect(() => {
      if (isCopyTasksSuccess) handleCloseModal();
    });

    /**
     * Обработчик открытия модального окна.
     */
    const handleOpenModal = useCallback(() => setIsModalOpen(true), []);

    /**
     * Обработчик изменения состояния филиала.
     * @param {TFilialData} filial - Данные филиала.
     */
    const onChangeHandler = useCallback(
      (filial: TFilialData): void => {
        if (filialsToCopy.includes(filial)) {
          const newFilialsList = filialsToCopy.filter(item => item.accId !== filial.accId);
          dispatch(setFilialsToCopy(newFilialsList));
        } else {
          dispatch(setFilialsToCopy([...filialsToCopy, filial]));
        }
      },
      [filialsToCopy, dispatch],
    );

    /**
     * Обработчик удаления филиала.
     * @param {TFilialData} filial - Данные филиала.
     */
    const deleteBalloonHandler = useCallback(onChangeHandler, [
      filialsToCopy,
      dispatch,
      onChangeHandler,
    ]);

    /**
     * Обработчик сохранения.
     */
    const onSaveHandler = useCallback(saveTemplateHandler, [saveTemplateHandler]);

    /**
     * Создание списка филиалов для копирования.
     * Если это текущий филиал, то он выбран по умолчнаию
     */
    const filials = useMemo(() => {
      return accounts.map(filial => {
        if (filial.accId === accIdLS) return null;
        const isSelected = filialsToCopy.includes(filial);

        return (
          <TaskCopyCheckbox
            key={filial.accId}
            text={filial.name}
            name={filial.name}
            disabled={filial.accId === accIdLS}
            onChangeHandler={() => onChangeHandler(filial)}
            checked={isSelected}
            className='w-full'
            dense={dense}
          />
        );
      });
    }, [filialsToCopy, onChangeHandler, accIdLS, dense, accounts]);

    /**
     * Текст плэйсхолдера с количеством выбранных филиалов
     */
    const dropDownText = useMemo(() => {
      return <span>{`Выбрано филиалов: ${filialsToCopy.length}`}</span>;
    }, [filialsToCopy]);

    /**
     * Подготовка списка филиалов для копирования к отображению
     */
    const balloons = useMemo(() => {
      return filialsToCopy.map((filial, index) => {
        const key = `${index}${filial.name}`;

        return (
          <Chips
            key={key}
            removable
            type='def'
            tag={filial.name}
            color='default'
            onRemove={() => deleteBalloonHandler(filial)}
            className='tracking-wide mr-2.5 mb-2.5'
          />
        );
      });
    }, [filialsToCopy, deleteBalloonHandler]);

    /**
     * Рендер дропдауна со списком филиалов
     */
    const dialogText = useMemo(() => {
      return isCopyTasksError ? (
        <div className='text-darkBrown'>
          <p>Шаблон не скопирован в филиалы, указанные ниже:</p>
          <ul className='list-disc px-7'>
            {rejectedFilialsToCopy.map(filial => {
              const currentFilial = filialsToCopy.find(item => item.accId === filial);
              return <li key={filial}>{currentFilial?.name}</li>;
            })}
          </ul>
        </div>
      ) : (
        <div style={dense ? {} : { width: 600 }}>
          <p className='mb-4'>
            Выберите филиалы, в которые будет скопирован шаблон. Статус шаблона
            (действующий/недействующий) будет скопирован для всех выбранных филиалов.
          </p>
          <div className='mb-4'>
            <Dropdown
              view='outlined'
              color='default'
              position='bottom-left'
              text={dropDownText}
              content={
                <Menu view='raised' className='!p-2 w-full'>
                  {filials}
                </Menu>
              }
              className='w-full nav_dropdown'
            />
          </div>
          <div className='flex items-center flex-wrap w-full'>{balloons}</div>
        </div>
      );
    }, [
      filials,
      balloons,
      dropDownText,
      dense,
      isCopyTasksError,
      rejectedFilialsToCopy,
      filialsToCopy,
    ]);

    const rendrerTitle = useMemo(() => {
      return isCopyTasksError ? 'Ошибка копирования' : 'Выберите филиалы';
    }, [isCopyTasksError]);

    return (
      <div className={className}>
        <Dialog
          view='raised'
          color={isCopyTasksError ? 'danger' : 'default'}
          title={rendrerTitle}
          size='small'
          style={dense ? {} : { maxWidth: 680 }}
          text={dialogText}
          rightButton={
            <div className='flex'>
              <Button
                dense
                type='action'
                text='Отмена'
                view='outlined'
                color='default'
                onClick={handleCloseModal}
                className='mr-6'
              />
              <Button
                dense
                type='action'
                view='filled'
                color='success'
                text={isCopyTasksError ? 'Повторить' : 'Скопировать'}
                disabled={filialsToCopy.length === 0}
                loading={isCopyTasksLoading}
                onClick={onSaveHandler}
              />
            </div>
          }
          backdropOpacity={40}
          isOpen={isModalOpen}
          onClose={handleCloseModal}
        />
        <Button
          icon={<Icon icon='duplicate' size={24} />}
          view='outlined'
          dense={dense}
          type='action'
          disabled={disabled}
          onClick={handleOpenModal}>
          Копировать
        </Button>
      </div>
    );
  },
);
