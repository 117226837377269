import React, { memo, useCallback, useMemo } from 'react';
//
import { Button } from '@uikit/Button';
import { INPUT_NAMES } from '@data/dict';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { TVisitQuantityOnChangeHandler } from '@shared/types';

import { FormLabel } from '../formLabel';

interface IVisitQuantityProps {
  /**
   * Значение от
   * @param {string}
   */
  quantityFrom: string;
  /**
   * Значение до
   * @param {string}
   */
  quantityTo: string;
  /**
   * Callback обрабатывает изменение данных в input
   * @param {TVisitQuantityOnChangeHandler}
   */
  onChangeHandler: TVisitQuantityOnChangeHandler;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const VisitQuantity = memo(
  ({ quantityFrom, quantityTo, onChangeHandler, className = '' }: IVisitQuantityProps) => {
    // валидирует поле "from" не позволяет ввести отрицательные значения и установить значение выше чем в поле "to"
    const validateFromValue = (quantityFromValue: string, quantityToValue: string) => {
      let newQuantityFromValue = '';
      let newQuantityToValue = '';

      if (!quantityFromValue) {
        return { newQuantityFromValue, newQuantityToValue };
      }
      if (+quantityToValue < 1) {
        return { newQuantityFromValue: '0', newQuantityToValue: '1' };
      }
      if (quantityFromValue !== '') {
        newQuantityFromValue = (+quantityFromValue < 0 ? 0 : quantityFromValue).toString();
      }

      newQuantityToValue = (
        +quantityToValue <= +quantityFromValue ? +quantityFromValue + 1 : +quantityToValue
      ).toString();

      return { newQuantityFromValue, newQuantityToValue };
    };
    // валидирует поле "to" не позволяет ввести отрицательные значения, минимальное значение 1,
    // не позволяет установить значение меньше чем поле "from"
    const validateToValue = (quantityFromValue: string, quantityToValue: string) => {
      let newQuantityFromValue = '';
      let newQuantityToValue = '';

      if (!quantityToValue) {
        return { newQuantityFromValue, newQuantityToValue };
      }
      if (+quantityToValue < 1) {
        return { newQuantityFromValue: '0', newQuantityToValue: '1' };
      }
      if (quantityToValue !== '') {
        newQuantityToValue = (+quantityToValue <= 0 ? 1 : quantityToValue).toString();
      }

      newQuantityFromValue = (
        +quantityFromValue >= +quantityToValue ? +quantityToValue - 1 : +quantityFromValue
      ).toString();

      return { newQuantityFromValue, newQuantityToValue };
    };
    // обрабатывает поля "from", "to", проверяет их на валидность, собирает данные и отправляет их
    // в родительский компонент через вызов коллбэка onChangeHandler
    const visitQuantityOnChangeHandler =
      (from: string, to: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = event.currentTarget;

        if (name === INPUT_NAMES.QUANTITY_FROM) {
          const { newQuantityFromValue, newQuantityToValue } = validateFromValue(value, to);
          onChangeHandler({ newQuantityFromValue, newQuantityToValue });
        }

        if (name === INPUT_NAMES.QUANTITY_TO) {
          const { newQuantityFromValue, newQuantityToValue } = validateToValue(from, value);
          onChangeHandler({ newQuantityFromValue, newQuantityToValue });
        }
      };

    const clearValueHandler = useCallback(() => {
      onChangeHandler({ newQuantityFromValue: '', newQuantityToValue: '' });
    }, [onChangeHandler]);

    const memoizedClearButton = useMemo(
      () => (
        <Button
          icon='cross'
          text='Очистить'
          view='outlined'
          color='default'
          onClick={clearValueHandler}
          disabled={!quantityTo || !quantityFrom}
          className='ml-[0.375rem]'
        />
      ),
      [clearValueHandler, quantityTo, quantityFrom],
    );

    return (
      <div className={` ${className}`}>
        <FormLabel
          showInfo={false}
          labelTextVariant='visitQuantity'
          htmlFor={INPUT_NAMES.QUANTITY_FROM}
          className='mb-2'
        />
        <div className='flex flex-wrap items-center'>
          <span className='mr-2.5'>от</span>
          <TextInput
            min='0'
            type='number'
            color='default'
            view='outlined'
            value={quantityFrom}
            name={INPUT_NAMES.QUANTITY_FROM}
            onChange={visitQuantityOnChangeHandler(quantityFrom, quantityTo)}
            className='max-w-[5.785rem]'
          />
          <span className='mx-2.5'>до</span>
          <TextInput
            min='1'
            type='number'
            color='default'
            view='outlined'
            value={quantityTo}
            name={INPUT_NAMES.QUANTITY_TO}
            onChange={visitQuantityOnChangeHandler(quantityFrom, quantityTo)}
            className='mr-2.5 max-w-[5.785rem]'
          />
          {memoizedClearButton}
        </div>
      </div>
    );
  },
);
