import React, { memo } from 'react';
//

import { Support } from '../support';

interface IFooterProps {
  /**
   * Ссылка на странице Бьюти бота
   * @param {string}
   */
  bbLandingPageLink: string;
  /**
   * Ссылка на канал поддержки в telegram
   * @param {string}
   */
  supportTelegramLink: string;
  /**
   * Ссылка на базу знаний
   * @param {string}
   */
  linkToKnowledgeBase: string;
  /**
   * Номер телефона в href, передается без форматирования (+77777777777)
   * @param {string}
   */
  supportPhoneToHref: string;
  /**
   * Телефонный номер на страницу, передается с форматированием для лучшей читаемости (+7 (777) 777 7777)
   * @param {string}
   */
  supportPhoneToShow: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const Footer = memo(
  ({
    bbLandingPageLink,
    supportTelegramLink,
    // eslint-disable-next-line
    linkToKnowledgeBase,
    supportPhoneToHref,
    supportPhoneToShow,
    className = '',
  }: IFooterProps) => (
    <footer className='absolute left-0 right-0 bottom-0 bg-footerGray w-full'>
      <div
        className={`bg-footerGray font-inter flex flex-wrap justify-between items-center m-auto min-h-[4rem] max-w-[101.5rem] w-full pl-4 pr-6 ${className}`}>
        <a
          href={bbLandingPageLink}
          className='no-underline font-inter text-small_text text-darkGray hidden md:inline hover:no-underline hover:text-darkGray'
          target='_blank'
          rel='noopener noreferrer'>
          © админбот.рф
        </a>
        <Support
          isFooter
          isShowPhone
          supportTelegramLink={supportTelegramLink}
          supportPhoneToHref={supportPhoneToHref}
          supportPhoneToShow={supportPhoneToShow}
        />
        <div className='flex flex-wrap items-center justify-between'>
          {/* <a
            href={linkToKnowledgeBase}
            className='flex flex-col justify-center items-center no-underline font-inter text-small_text text-darkGray hover:no-underline hover:text-darkGray'
            target='_blank'
            rel='noopener noreferrer'>
            <Icon variant='learning' />
            База знаний
           </a> */}
        </div>
      </div>
    </footer>
  ),
);
