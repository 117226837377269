import React, { useEffect } from 'react';
//
import { DASHBOARD_TYPES } from '@api/types';
import { NoAccess } from '@components/noAccess';
import { ErrorMessage } from '@blocks/errorMessage';
import { AdminsTable } from '@templates/adminsTable';
import { getDateStringToGetData } from '@helpers/index';
import { getDashboardData } from '@redux/Dashboard/thunks';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { getAccountsData, getDashboardPageData, getSettingsData } from '@redux/selectors';

const AdminsTablePage = () => {
  const dispatch = useAppDispatch();
  const { selectedFilial } = useAppSelector(getAccountsData);
  const selectedFilialAccId = selectedFilial?.accId || '';

  // Получаем и деструктурезируем данные для таблицы
  const {
    isAccessDenied,
    telephonyTableData: data,
    isError: telephonyGetDataError,
  } = useAppSelector(getSettingsData);

  // Получаем и деструктурезируем данные для графика
  const {
    isError,
    isLoading,
    isRetries,
    adminsEndDate,
    isFetchingMeta,
    adminsStartDate,
    isFetchingMetaSuccess,
  } = useAppSelector(getDashboardPageData);

  // Эффект запрашивает данные для графика повторно при ошибке токена
  useEffect(() => {
    if (isRetries) {
      setTimeout(() => {
        dispatch(
          getDashboardData({
            force: true,
            type: DASHBOARD_TYPES.ADMINS,
            startDate: getDateStringToGetData(adminsStartDate),
            endDate: getDateStringToGetData(adminsEndDate),
          }),
        );
      }, 100);
    }
  }, [dispatch, adminsStartDate, adminsEndDate, isRetries]);

  // Эффект запрашивает данные для графика при загрузке страницы и смене филиала
  useEffect(() => {
    if (selectedFilialAccId && !isFetchingMeta && isFetchingMetaSuccess) {
      dispatch(
        getDashboardData({
          force: true,
          type: DASHBOARD_TYPES.ADMINS,
          startDate: getDateStringToGetData(adminsStartDate),
          endDate: getDateStringToGetData(adminsEndDate),
        }),
      );
    }
  }, [
    dispatch,
    adminsEndDate,
    isFetchingMeta,
    adminsStartDate,
    selectedFilialAccId,
    isFetchingMetaSuccess,
  ]);

  return (
    <>
      {!isLoading && !isError && !isFetchingMeta && !isAccessDenied && !telephonyGetDataError ? (
        <AdminsTable telephonyTableData={data} selectedFilialAccId={selectedFilialAccId} />
      ) : null}
      {isAccessDenied ? <NoAccess /> : null}
      {isError || telephonyGetDataError ? <ErrorMessage /> : null}
    </>
  );
};

export default AdminsTablePage;
