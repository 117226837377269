import { z } from 'zod';

export const TelegramAuthResponseDataSchema = z.object({
  id: z.number(),
  first_name: z.string(),
  last_name: z.string(),
  username: z.string(),
  photo_url: z.string(),
  auth_date: z.number(),
  hash: z.string(),
});

export const TelegramAuthDataSchema = z.object({
  id: z.number(),
  firstName: z.string(),
  lastName: z.string(),
  username: z.string(),
  photoUrl: z.string(),
});

export const AuthResponseSchema = z.object({
  access_token: z.string(),
  user_id: z.number(),
  auth: z.boolean(),
});

export const TelegramPinAuthResponse = AuthResponseSchema.extend({
  first_name: z.string(),
  last_name: z.string(),
  username: z.string(),
  photo_url: z.string(),
});

export const LoginDataSchema = z.object({
  accessToken: z.string(),
  userId: z.number(),
  auth: z.boolean(),
  authUserData: TelegramAuthDataSchema,
});

export const LoginUserByPinSchema = z.object({
  userId: z.number(),
  pin: z.number(),
});

export type TTelegramAuthResponseData = z.infer<typeof TelegramAuthResponseDataSchema>;

export type TAuthResponse = z.infer<typeof AuthResponseSchema>;

export type TTelegramAuthData = z.infer<typeof TelegramAuthDataSchema>;

export type TTelegramPinAuthResponse = z.infer<typeof TelegramPinAuthResponse>;

export type TLoginData = z.infer<typeof LoginDataSchema>;

export type TLoginUserByPin = z.infer<typeof LoginUserByPinSchema>;
