import React from 'react';
//
import { Button } from '@uikit/Button';
import { CONFIG } from '@const/config';
import { Icon } from '@blueprintjs/core';
import { ReactComponent as TelegramIcon } from '@img/baseTemplate/telegramCyan.svg';
import { ReactComponent as ConnectionErrorIcon } from '@img/settings/connectionError.svg';

type TTelephonyConnectionErrorMessageProps = {
  /**
   * Флаг состояния запроса
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Ссылка на инструкцию по подключению телефонии
   * @param {string}
   */
  instructionLink: string;
  /**
   * Проверяет возможность подключения телефонии с указанными данными
   * @param {() => void}
   */
  checkConnection: () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const TelephonyConnectionErrorMessage = ({
  isLoading,
  checkConnection,
  instructionLink,
  className = '',
}: TTelephonyConnectionErrorMessageProps) => {
  return (
    <div className={`flex flex-col items-center justify-center ${className}`}>
      <ConnectionErrorIcon className='mt-[0.1875rem] mb-8' />
      <p className='font-bold text-black leading-6 tracking-[0.0275em] mb-2'>
        Ошибка получения данных во время интеграции
      </p>
      <div className='flex flex-col items-center'>
        <p className='text-black leading-6 tracking-[0.0275em] mr-2'>
          Пожалуйста, проверьте следующее:
        </p>
        <ol className='flex flex-col items-center list-decimal mb-6'>
          <li>Убедитесь, что у вас есть активное подключение к Интернету</li>
          <li>Проверьте правильность введенных данных</li>
        </ol>
        <p className='text-black leading-6 tracking-[0.0275em] mr-2'>
          Если проблема сохраниться, воспользуйтесь
          <a
            target='_blank'
            rel='noreferrer noopener'
            href={instructionLink}
            className='no-underline text-statusGreen leading-6 tracking-[0.0275em]'>
            &nbsp;инструкцией&nbsp;
            <Icon icon='share' color='#65B168' size={20} />
          </a>
        </p>
        <div className='flex'>
          <p className='text-black leading-6 tracking-[0.0275em] m-0'>или обратитесь в&nbsp;</p>
          <div className='flex items-center'>
            <a
              target='_blank'
              rel='noreferrer noopener'
              href={CONFIG.SUPPORT_TELEGRAM_LINK}
              className='no-underline text-telegramCian leading-6 tracking-[0.0275em] mr-1'>
              тех.поддержку
            </a>
            <TelegramIcon />
          </div>
        </div>
      </div>
      <Button
        dense
        type='action'
        color='default'
        view='outlined'
        onClick={checkConnection}
        text='Проверить подключение'
        loading={isLoading}
        className='mt-6 mb-9'
      />
    </div>
  );
};
