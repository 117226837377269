import { log } from '@helpers/index';
import { api } from '@api/analytics/api';
import { TRejectValue } from '@models/index';
import { REJECT_RESPONSE_KEY } from '@api/types';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { mapAnalyticsResponseToStore } from '@redux/Analytics/mappers';
import {
  TMeta,
  TGeneral,
  TAnalyticsAdmin,
  analyticsResponseSchema,
  TGetAnalyticsAdminRequest,
  TSaveAnalyticsMetaRequest,
} from '@models/Analytics';

export const getAdminsAnalyticsThunk = createAsyncThunk<
  {
    adminsList: TAnalyticsAdmin[];
    generalData: TGeneral;
    selectedCharts: TMeta;
  },
  TGetAnalyticsAdminRequest,
  TRejectValue
>('analytics/getAdminsAnalyticsThunk', (data, { rejectWithValue }) => {
  return api.admins
    .get(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(String(response.data));
      }

      const validData = analyticsResponseSchema.safeParse(response.data);

      if (!validData.success) {
        log('@@getAdminsAnalyticsThunk: ', validData.error?.format());
        throw new Error(String(validData.error));
      }

      return mapAnalyticsResponseToStore(validData.data);
    })
    .catch(error => rejectWithValue(error.response.data));
});

export const saveAnalyticsMetaThunk = createAsyncThunk<
  void,
  TSaveAnalyticsMetaRequest,
  TRejectValue
>('analytics/saveAnalyticsMetaThunk', (data, { rejectWithValue }) => {
  return api.meta
    .post(data)
    .then(response => {
      log('@@saveAnalyticsMetaThunk: ', response.data);
    })
    .catch(error => rejectWithValue(error.response.data));
});
