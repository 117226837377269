import { TReportData } from '@models/Report';
import { TPayData, TPayerData } from '@models/Pay';
import { TTaskData, TWeekDays } from '@models/Tasks';
import { numberOfGiftWeek, numberOfMonth } from '@const/pay';
import { TSettingsData, TSettingStaff } from '@models/Settings';
import { DATA_CALCULATION_PERIOD, REPORT_SEND_PERIOD } from '@const/report';
import {
  TASK_TYPE,
  SEND_TIME,
  EVENTS_TYPES,
  SENDING_PERIODS,
  TELEPHONY_EVENTS,
  BB_INTEGRATION_EVENTS,
} from '@const/task';

// Task page
export const initialWeekDaysData: TWeekDays = {
  '1': true,
  '2': false,
  '3': false,
  '4': false,
  '5': false,
  '6': false,
  '7': false,
};

export const emptyWeekDaysData: TWeekDays = {
  '1': false,
  '2': false,
  '3': false,
  '4': false,
  '5': false,
  '6': false,
  '7': false,
};

export const emptyTaskData: TTaskData = {
  id: '', //
  isActive: false, //
  taskType: TASK_TYPE.CASE, //
  whenSend: '', // ? буду вычислять на основе других параметров ? для существующих задач на основе выбранной страницы?
  sendingPeriod: '', // day || week || month // можно понять какой период но наличию строк frequency - day, week-week, month-month
  sendingTime: '10:00', //
  frequency: 1,
  sendWeekDays: initialWeekDaysData, //
  monthSendDays: '1', //
  sendOnLastDayOfMonth: false, // нет такого параметра
  daysWithoutVisiting: '1', // нет такого параметра
  selectedEventType: '', // нет четкого понимания соответствия с параметрами на бэке
  selectedTelephonyType: '', // нет параметра
  selectedYclientsType: '', // нет параметра
  selectedCrmType: '', // нет параметра
  discountAmount: '1', // нет параметра
  clientDelayed: '1', // нет параметра
  taskName: '', //
  taskDescription: '', //
  isAddChecklist: false, // можно проверять длину параметра checklist
  checklistItems: [], //
  selectedBBIntegrationType: '', // нет параметра
  selectedWorkStatisticsType: '', // нет параметра
  missedCallsPercents: '1', // нет параметра
  thresholdWhatsappResponseRate: '1', // нет параметра
  thresholdLowEnrolledPercentage: '1', // нет параметра
  selectedClientEvent: '',
  notRecord: '1',
  lateReply: '1',
  daysAfterVisit: '',
  services: [],
  visits: [],
  staff: [],
  report: false,
};

export const newTaskData: TTaskData = {
  id: '',
  isActive: false,
  taskType: TASK_TYPE.CASE,
  whenSend: SEND_TIME.REGULAR,
  sendingPeriod: SENDING_PERIODS.DAY,
  sendingTime: '10:00',
  frequency: 1,
  sendWeekDays: initialWeekDaysData,
  monthSendDays: '1',
  sendOnLastDayOfMonth: false,
  daysWithoutVisiting: '1',
  selectedEventType: EVENTS_TYPES.TELEPHONY,
  selectedTelephonyType: TELEPHONY_EVENTS.MISSED_CALL,
  selectedYclientsType: '',
  selectedCrmType: '',
  discountAmount: '1',
  clientDelayed: '1',
  taskName: '',
  taskDescription: '',
  isAddChecklist: false,
  checklistItems: [],
  selectedBBIntegrationType: BB_INTEGRATION_EVENTS.BAD_FEEDBACK,
  selectedWorkStatisticsType: '',
  missedCallsPercents: '',
  thresholdWhatsappResponseRate: '',
  thresholdLowEnrolledPercentage: '',
  selectedClientEvent: '',
  notRecord: '1',
  lateReply: '1',
  daysAfterVisit: '',
  staff: [],
  visits: [],
  services: [],
  report: false,
};

// Report page
export const reportEmptyData: TReportData = {
  id: '',
  isActive: false,
  reportName: '',
  reportDescription: '',
  selectedReportSendPeriod: DATA_CALCULATION_PERIOD.TODAY,
  sendingTime: '22:00',
  sendingDate: '',
  selectedDataCalculatingPeriod: REPORT_SEND_PERIOD.EVERY_DAY,
  selectedEveryWeekSendingPeriod: DATA_CALCULATION_PERIOD.MONDAY,
  selectedEveryMonthSendingPeriod: DATA_CALCULATION_PERIOD.FIRST_DAY_OF_MONTH,
  telegramRecipients: [],
  whatsappRecipients: [],
  sendToAllStaff: false,
};

// Settings page
export const emptySettingsData: TSettingsData = {
  callStats: false,
  staffList: {
    123: 'Иванов',
    456: 'Сидоров',
    789: 'Петров',
  },
  employeesToIgnore: [],
  selectedStaff: '',
  missedCallAnswer: false,
  customerChurnTime: '90',
  churnTimeAllowUpdate: '2023-11-29T18:45:25.894966',
  paramsCalcStats: {
    startRate: 1,
    endRate: 1,
  },
  useGroups: false,
};

// Settings staff
export const staffsTablesData: TSettingStaff[] = [
  {
    id: 1083512,
    name: 'Аринова Татьяна',
    staffLink: 'http://t.me/salon_adminbot?start=340011_213456',
    status: 1,
  },
  {
    id: 10835122,
    name: 'Аринова Татьяна2',
    staffLink: 'http://t.me/salon_adminbot?start=340011_213456',
    status: 1,
  },
];

export const employeesToIgnore = [
  {
    id: 5872948,
    name: 'Тест',
  },
  {
    id: 12143842,
    name: 'Тест',
  },
];

export const staffList = [
  {
    id: 1083512,
    name: 'Сотрудник 1',
  },
  {
    id: 2376859,
    name: 'АдминБот',
  },
];

// Pay page
export const payPageEmptyData: TPayData = {
  amount: '',
  isAutoPayEnable: false,
  discount: 0,
  branch: '',
  branchEmployees: '',
  cardLastNum: '',
  payTillDate: '',
  numberOfPriceWithBonus: [1791, 1791, 1791, 1791],
  numberOfMonth,
  numberOfGiftWeek,
  payStatus: false,
  paymentData: [],
};

export const payerEmptyData: TPayerData = {
  name: '',
  inn: '',
  kpp: '',
};

export const payLinkEmptyData = {
  link: '',
  telegramStatus: false,
};
