import React, { memo } from 'react';
//
import { Menu } from '@uikit/Menu';
import { Dropdown } from '@uikit/Dropdown';
import { MenuItem } from '@uikit/Menu/MenuItem';

interface IDropdownWithIconProps {
  /**
   * Значение выбранного элемента
   * @param {string}
   */
  selectedItem: string;
  /**
   * Словарь для получения элементов выпадающего списка
   * @param {{ [key: string]: string }}
   */
  items: { [key: string]: string };
  /**
   * Callback срабатывает при клике на элемент выпадающего списка
   * @param {(name: string) => () => void}
   */
  callBack: (name: string) => () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
}

export const DropdownWithIcon = memo(
  ({ items, callBack, selectedItem, className = '' }: IDropdownWithIconProps) => {
    const IS_ICON_SIGN = 'I';
    // Подготавливает массив MenuItem для рендеренга,
    // если в тексте присутствует буква I, добавляется класс icon
    // если у пункта есть класс icon справа будет отображаться иконка
    const itemsToRender = Object.entries(items).map(item => (
      <MenuItem
        key={item[0]}
        text={
          <span className={item[1]?.includes(IS_ICON_SIGN) ? 'icon' : ''}>
            {item[1]?.includes(IS_ICON_SIGN) ? item[1].slice(0, item[1].length - 2) : item[1]}
          </span>
        }
        onClick={callBack(item[0])}
      />
    ));

    // Получаем текст для dropdown
    const selectedItemText = items[selectedItem];

    // обрабатываем полученный текст, удаляем букву I
    const dropdownText = selectedItemText?.includes(IS_ICON_SIGN)
      ? selectedItemText.slice(0, selectedItemText.length - 2)
      : selectedItemText || 'Выберите';

    return (
      <Dropdown
        color='default'
        view='outlined'
        position='bottom-left'
        className={`when_send_dropdown w-full ${className}`}
        text={
          <span className={items[selectedItem]?.includes(IS_ICON_SIGN) ? 'icon' : ''}>
            {dropdownText}
          </span>
        }
        content={
          <Menu className={itemsToRender.length === 1 ? `one_item_ul` : ''} view='raised'>
            {itemsToRender}
          </Menu>
        }
      />
    );
  },
);
