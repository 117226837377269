import React, { useCallback, useEffect } from 'react';
//
import { Button } from '@uikit/Button';
import { getPayPageState } from '@redux/selectors';
import { SETTINGS_DATA_KEY_NAMES } from '@const/settings';
import { ToastsMessages } from '@components/toastsMessages';
import { MIN_INN_LENGTH, MIN_NAME_LENGTH } from '@const/pay';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { IconWithTooltips } from '@components/iconWithTooltips';
import { SettingInputWithLabel } from '@blocks/settingInputWithLabel';
import { getPayerDataThunk, setPayerDataThunk } from '@redux/Pay/thunk';
import { clearPayToastMessages, setPayerInn, setPayerKpp, setPayerName } from '@redux/Pay/slice';

export const SettingsPayTemplate = () => {
  const { isPayerDataSaving, isLoading, successToastMessage, errorToastMessage, payerFormData } =
    useAppSelector(getPayPageState);
  const dispatch = useAppDispatch();

  const { name, inn } = payerFormData;

  const validName = name && name.length >= MIN_NAME_LENGTH;
  const validInn = inn && inn.length >= MIN_INN_LENGTH;

  const setPayerNameHandler = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setPayerName(value));
    },
    [dispatch],
  );

  const setPayerInnHandler = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setPayerInn(value));
    },
    [dispatch],
  );

  const setPayerKppHandler = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      dispatch(setPayerKpp(value));
    },
    [dispatch],
  );

  const clearInputHandler = useCallback(
    (variant: SETTINGS_DATA_KEY_NAMES) => () => {
      const emptyValue = { target: { value: '' } } as React.ChangeEvent<HTMLInputElement>;
      switch (variant) {
        case SETTINGS_DATA_KEY_NAMES.INN:
          setPayerInnHandler(emptyValue);
          break;
        case SETTINGS_DATA_KEY_NAMES.KPP:
          setPayerKppHandler(emptyValue);
          break;
        default:
          setPayerNameHandler(emptyValue);
      }
    },
    [setPayerNameHandler, setPayerInnHandler, setPayerKppHandler],
  );

  const onSaveHandler = useCallback(() => {
    if (validName && validInn) {
      dispatch(setPayerDataThunk(payerFormData));
    }
  }, [dispatch, payerFormData, validInn, validName]);

  const clearToastMessages = useCallback(() => {
    dispatch(clearPayToastMessages());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getPayerDataThunk());

    return () => {
      clearToastMessages();
    };
  }, [dispatch, clearToastMessages]);

  return (
    <div className='mb-8 max-w-[45.75rem] w-full'>
      <div className='flex items-center mb-4'>
        <p className='flex items-center font-medium text-black text-h3Mobile tracking-[0.0075em] mr-2'>
          Данные счета
        </p>
        <IconWithTooltips tooltips='billData' className='mb-1' />
      </div>
      <SettingInputWithLabel
        isRequire
        error={payerFormData.name ? payerFormData.name.length < MIN_NAME_LENGTH : false}
        errorText='Некорректное наименование юр.лица'
        value={payerFormData.name || ''}
        onChangeHandler={setPayerNameHandler}
        htmlFor={SETTINGS_DATA_KEY_NAMES.NAME}
        clearSearchString={clearInputHandler(SETTINGS_DATA_KEY_NAMES.NAME)}
        textColor='text-blackHeader'
        className='mb-8 w-full'>
        Наименование юр.лица
      </SettingInputWithLabel>
      <SettingInputWithLabel
        isRequire
        error={payerFormData.inn ? payerFormData.inn.length < MIN_INN_LENGTH : false}
        errorText='Некорректный ИНН'
        value={payerFormData.inn || ''}
        onChangeHandler={setPayerInnHandler}
        htmlFor={SETTINGS_DATA_KEY_NAMES.INN}
        clearSearchString={clearInputHandler(SETTINGS_DATA_KEY_NAMES.INN)}
        textColor='text-blackHeader'
        className='mb-8 w-full'>
        ИНН
      </SettingInputWithLabel>
      <SettingInputWithLabel
        value={payerFormData.kpp || ''}
        onChangeHandler={setPayerKppHandler}
        htmlFor={SETTINGS_DATA_KEY_NAMES.KPP}
        clearSearchString={clearInputHandler(SETTINGS_DATA_KEY_NAMES.KPP)}
        textColor='text-blackHeader'
        className='mb-8 w-full'>
        КПП
      </SettingInputWithLabel>
      <Button
        dense
        type='action'
        view='filled'
        color='success'
        loading={isPayerDataSaving}
        text='Сохранить настройки'
        onClick={onSaveHandler}
        disabled={isLoading || isPayerDataSaving || !validName || !validInn}
        className='w-fit'
      />
      <ToastsMessages
        errorMessage={errorToastMessage}
        successMessage={successToastMessage}
        isShowErrorToast={!!errorToastMessage}
        isShowSuccessToast={!!successToastMessage}
        clearSuccessStatusCallback={clearToastMessages}
        clearErrorCallback={clearToastMessages}
      />
    </div>
  );
};
