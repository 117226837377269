import React, { ChangeEvent, memo, useCallback, useMemo, useState } from 'react';
//
import { H1 } from '@atoms/h1';
import { Button } from '@uikit/Button';
import { Dialog } from '@uikit/Dialog';
import { CONFIG } from '@const/config';
import { Loader } from '@blocks/loader';
import { TaskCopy } from '@blocks/taskCopy';
import { TaskType } from '@blocks/taskBlock';
import { TFilialData } from '@models/Accounts';
import { EventsBlock } from '@blocks/eventsBlock';
import { ClientsBlock } from '@blocks/clientBlock';
import { RegularBlock } from '@blocks/regularBlock';
import { ToggleSwitch } from '@blocks/toggleSwitch';
import { ErrorMessage } from '@blocks/errorMessage';
import { TTaskData, TWeekDays } from '@models/Tasks';
import { setTaskTypeToPath } from '@redux/App/slice';
import { TaskPathLinks } from '@blocks/taskPathLinks';
import { TaskPreview } from '@components/taskPreview';
import { TSimpleStringObjLevel2 } from '@models/index';
import { CheckListBlock } from '@components/checklistBlock';
import { ToastsMessages } from '@components/toastsMessages';
import { saveTask, saveTasksCopy } from '@redux/Task/thunks';
import { TaskDescription } from '@components/taskDescription';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { DropdownWithIcon } from '@components/dropdownWithIcon';
import { TaskAdditionalInfo } from '@blocks/taskAdditionalInfo';
import { IconWithTooltips } from '@components/iconWithTooltips';
import { getAccountsData, getTaskPageData } from '@redux/selectors';
import { TOnChangeHandler, TOnChangeHandlerInputTextarea } from '@shared/types';
import {
  sendTime,
  SEND_TIME,
  EVENTS_TYPES,
  TELEPHONY_EVENTS,
  TYPE_OF_TEMPLATE,
  TASK_DATA_KEY_NAMES,
  BB_INTEGRATION_EVENTS,
  WORK_STATISTICS_EVENTS,
} from '@const/task';
import {
  setTaskName,
  setWhenSend,
  setTaskData,
  clearErrorsTP,
  setTaskStatus,
  clearSuccessTP,
  setSendWeekDay,
  setMonthSendDay,
  setSendingPeriod,
  setClientDelayed,
  sortMonthSendDay,
  setChecklistItems,
  setDaysAfterVisit,
  setDiscountAmount,
  setIsAddChecklist,
  setLateReplyLimit,
  setNotRecordLimit,
  setSelectedCrmType,
  setTaskDescription,
  setTaskSendingTime,
  addKeyInTextareaTP,
  deleteChecklistItem,
  setSelectedEventType,
  setTaskSendFrequency,
  editChecklistItemName,
  setDaysWithoutVisiting,
  setMissedCallsPercents,
  setSelectedClientEvent,
  setSelectedYclientsType,
  setNewChecklistItemName,
  setSendOnLastDayOfMonth,
  setSelectedBBIntegration,
  setSelectedTelephonyType,
  setAbandonedTextareaDataTP,
  setSelectedWorkStatisticsType,
  setThresholdWhatsappResponseRate,
  setThresholdLowEnrolledPercentage,
} from '@redux/Task/slice';

import { ReportRequire } from 'ui/blocks/reportRequire';

interface ITaskTemplateProps {
  /**
   * Флаг ошибки при запросе данных для страницы
   * @param {boolean}
   */
  isError: boolean;
  /**
   * Флаг показывает что запрос на сохранение данных процессе
   * @param {boolean}
   */
  isSaving: boolean;
  /**
   * Флаг показывает что запрос данных для страницы в процессе
   * @param {boolean}
   */
  isLoading: boolean;
  /**
   * Значение инпута для ввода нового значения фильтра по сотрудникам
   * @param {string}
   */
  newStaffValue: string;
  /**
   * Параметр для формирования ссылки на соответствующий раздел задач
   * @param {string}
   */
  taskTypeToPath: string;
  /**
   * Флаг ошибки при сохранении данных
   * @param {boolean}
   */
  isSavingError: boolean;
  /**
   * Состояние интеграции с Бьюти бот
   * @param {boolean}
   */
  isBBIntegrated: boolean;
  /**
   * Флаг успешного сохранения данных
   * @param {string}
   */
  isSavingSuccess: boolean;
  /**
   * Значение инпута для ввода нового значения фильтра по услугам
   * @param {string}
   */
  newServicesValue: string;
  /**
   * Значение инпута для ввода нового значения элемента чеклиста
   * @param {string}
   */
  newChecklistItemName: string;
  /**
   * Объект с данными выбранного филиала,
   * @param {TFilialData | null}
   */
  selectedFilial: TFilialData | null;
  /**
   * Текст сообщения об ошибке
   * @param {string}
   */
  errorToastMessage: string;
  /**
   * Текст сообщения об успешной операции
   * @param {string}
   */
  successToastMessage: string;
  /**
   * Объект с данными сотрудников и услуг
   * @param {TSimpleStringObjLevel2}
   */
  categoriesDataList: TSimpleStringObjLevel2;
  /**
   * Флаг успешного получения данных страницы настроек
   * @param {boolean}
   */
  isSuccess: boolean;
  /**
   * Данные страницы
   * @param {TTaskData}
   */
  data: TTaskData;
}

export const TaskTemplate = memo(
  ({
    data,
    isError,
    isSaving,
    isLoading,
    isSuccess,
    isSavingError,
    newStaffValue,
    isBBIntegrated,
    selectedFilial,
    taskTypeToPath,
    isSavingSuccess,
    newServicesValue,
    errorToastMessage,
    categoriesDataList,
    successToastMessage,
    newChecklistItemName,
  }: ITaskTemplateProps) => {
    const dispatch = useAppDispatch();
    const {
      blurFromTextareaName,
      filialsToCopy,
      errorToastMessage: taskErrorMessage,
      successToastMessage: taskSuccessMessage,
      isCopyTasksError,
      isCopyTasksSuccess,
    } = useAppSelector(getTaskPageData);
    const { data: accounts } = useAppSelector(getAccountsData);
    const {
      id,
      isActive,
      taskType,
      whenSend,
      sendingPeriod,
      frequency,
      sendingTime,
      sendWeekDays,
      monthSendDays,
      sendOnLastDayOfMonth,
      daysWithoutVisiting,
      daysAfterVisit,
      taskName,
      taskDescription,
      isAddChecklist,
      checklistItems,
      selectedEventType,
      selectedTelephonyType,
      selectedYclientsType,
      selectedCrmType,
      discountAmount,
      clientDelayed,
      selectedBBIntegrationType,
      selectedWorkStatisticsType,
      missedCallsPercents,
      thresholdWhatsappResponseRate,
      thresholdLowEnrolledPercentage,
      selectedClientEvent,
      notRecord,
      lateReply,
      staff,
      visits,
      services,
      report,
    } = data;

    const [isOpen, setIsOpen] = useState<boolean>(false);

    const modalToggle = useCallback(() => setIsOpen(prevState => !prevState), []);

    // Сохраняется выбранная пользователем textarea для возвращения фокуса после добавления спец ключей или обрамления текста
    const [textarea, setTextarea] = useState<EventTarget & HTMLTextAreaElement>();

    // блок обработчиков, устанавливают новые значения в state
    const setWhenSendDay = useCallback(
      (weekDay: string) => () => {
        dispatch(setSendWeekDay({ weekDay: weekDay as unknown as keyof TWeekDays }));
      },
      [dispatch],
    );

    const selectWhenSend = useCallback(
      (selectedTaskType: string) => () => {
        dispatch(setTaskTypeToPath({ taskType: selectedTaskType }));
        dispatch(setWhenSend({ whenSend: selectedTaskType }));
      },
      [dispatch],
    );

    const selectSendingPeriod = useCallback(
      (period: string) => () => {
        dispatch(setSendingPeriod({ periodName: period }));
      },
      [dispatch],
    );

    const setWhenTime = useCallback(
      (value: string) => {
        dispatch(setTaskSendingTime({ time: value }));
      },
      [dispatch],
    );

    const setFrequency = useCallback(
      (value: number) => {
        dispatch(setTaskSendFrequency({ frequency: value }));
      },
      [dispatch],
    );

    const setMonthSendDayHandler = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        dispatch(setMonthSendDay({ monthDays: value }));
      },
      [dispatch],
    );

    const sortMonthSendDaysHandler = useCallback(() => {
      dispatch(sortMonthSendDay());
    }, [dispatch]);

    const setSendOnLastDayOfMonthHandler: TOnChangeHandler = useCallback(
      event => {
        const { checked } = event.currentTarget;
        dispatch(setSendOnLastDayOfMonth({ checked }));
      },
      [dispatch],
    );

    const setNoVisitsDaysHandler = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        dispatch(setDaysWithoutVisiting({ days: value }));
      },
      [dispatch],
    );

    const setDaysAfterVisitsHandler = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        dispatch(setDaysAfterVisit({ days: value }));
      },
      [dispatch],
    );

    const setTaskNameHandler = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        dispatch(setTaskName({ taskName: value }));
      },
      [dispatch],
    );

    const setTaskDescriptionHandler: TOnChangeHandlerInputTextarea = useCallback(
      event => {
        const { value } = event.currentTarget;
        dispatch(setTaskDescription({ taskDescription: value }));
      },
      [dispatch],
    );

    const setIsAddCheckListToggleHandler = useCallback(() => {
      dispatch(setIsAddChecklist({ isAddChecklist: !isAddChecklist }));
    }, [isAddChecklist, dispatch]);

    const setNewChecklistNameHandler = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.currentTarget;
        dispatch(setNewChecklistItemName({ newValue: value }));
      },
      [dispatch],
    );

    const editCheckListItemValue = useCallback(
      (oldValue: string) => (newValue: string) => {
        dispatch(editChecklistItemName({ oldValue, newValue }));
      },
      [dispatch],
    );

    const addChecklistItem = useCallback(() => {
      dispatch(setChecklistItems());
      dispatch(setNewChecklistItemName({ newValue: '' }));
    }, [dispatch]);

    const deleteChecklistItemHandler = useCallback(
      (index: number) => (value: string) => () => {
        dispatch(deleteChecklistItem({ value, index }));
      },
      [dispatch],
    );

    const setSelectedEventTypeHandler = useCallback(
      (eventType: string) => () => {
        dispatch(setSelectedEventType({ eventType }));
      },
      [dispatch],
    );

    const setSelectedTelephonyEventHandler = useCallback(
      (telephonyEvent: string) => () => {
        dispatch(setSelectedTelephonyType({ telephonyType: telephonyEvent }));
      },
      [dispatch],
    );

    const setSelectedYclientsTypeHandler = useCallback(
      (yclientsEvent: string) => () => {
        dispatch(setSelectedYclientsType({ yclientsEventType: yclientsEvent }));
      },
      [dispatch],
    );

    const setSelectedCrmTypeHandler = useCallback(
      (crmEvent: string) => () => {
        dispatch(setSelectedCrmType({ crmEventType: crmEvent }));
      },
      [dispatch],
    );

    const setDiscountHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;
        dispatch(setDiscountAmount({ value }));
      },
      [dispatch],
    );

    const setClientDelayHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;
        dispatch(setClientDelayed({ delayAmount: value }));
      },
      [dispatch],
    );

    const setSelectedBBIntegrationTypeHandler = useCallback(
      (bbIntegrationType: string) => () => {
        dispatch(setSelectedBBIntegration({ bbIntegrationType }));
      },
      [dispatch],
    );

    const setSelectedWorkStatisticsTypeHandler = useCallback(
      (workStatisticsType: string) => () => {
        dispatch(setSelectedWorkStatisticsType({ workStatisticsType }));
      },
      [dispatch],
    );

    const setMissedCallsPercentsHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;
        dispatch(setMissedCallsPercents({ value }));
      },
      [dispatch],
    );

    const setThresholdWhatsappResponseRateHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;
        dispatch(setThresholdWhatsappResponseRate({ value }));
      },
      [dispatch],
    );

    const setThresholdLowEnrolledPercentageHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;
        dispatch(setThresholdLowEnrolledPercentage({ value }));
      },
      [dispatch],
    );

    const setClientsEvent = useCallback(
      (clientEventsType: string) => () => {
        dispatch(setSelectedClientEvent({ clientEvent: clientEventsType }));
      },
      [dispatch],
    );

    const setNotRecordLimitHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;
        dispatch(setNotRecordLimit({ limit: value }));
      },
      [dispatch],
    );

    const setLateReplyLimitHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;
        dispatch(setLateReplyLimit({ limit: value }));
      },
      [dispatch],
    );

    const taskStatusToggle = useCallback(() => {
      dispatch(setTaskStatus({ status: !isActive }));
    }, [isActive, dispatch]);

    const saveTaskHandler = useCallback(() => {
      if (!filialsToCopy.length) {
        dispatch(saveTask(data));
      } else {
        dispatch(saveTasksCopy({ data, filials: filialsToCopy }));
      }
    }, [data, dispatch, filialsToCopy]);

    // Проверяем выбраны ли опции в настройках которые зависят от интеграции с бьюти ботом
    const isDisabledWhenNoBBIntegration = useMemo(
      () =>
        (selectedEventType === EVENTS_TYPES.BB_INTEGRATION && !isBBIntegrated) ||
        (selectedEventType === EVENTS_TYPES.WORK_STATISTICS &&
          !isBBIntegrated &&
          (selectedWorkStatisticsType === WORK_STATISTICS_EVENTS.WHATSAPP_RESPONSE_RATE ||
            selectedWorkStatisticsType === WORK_STATISTICS_EVENTS.LOW_ENROLLED_PERCENTAGE)),
      [isBBIntegrated, selectedEventType, selectedWorkStatisticsType],
    );

    // Проверяем на заполнение обязательные поля в задаче
    const isTaskNoValidToSave = useMemo(
      () => !taskName || !taskDescription,
      [taskName, taskDescription],
    );

    // Обработчик устанавливает значения при потери фокуса с textarea
    const onTextareaBlurHandler = useCallback(
      (event: React.FocusEvent<HTMLTextAreaElement>) => {
        const {
          name,
          selectionStart: startOfSelectText,
          selectionEnd: endOfSelectText,
        } = event.currentTarget;
        setTextarea(event.currentTarget);
        dispatch(
          setAbandonedTextareaDataTP({
            name,
            selectionStart: startOfSelectText,
            selectionEnd: endOfSelectText,
          }),
        );
      },
      [dispatch],
    );

    // Callback передается в компонент <TaskAdditionalInfo />
    // добавляет спец ключ в текс 'выбранной' textarea
    const setKeyInTextarea = useCallback(
      (key: string) => () => {
        if (blurFromTextareaName) {
          dispatch(addKeyInTextareaTP({ innerText: key }));
          textarea?.focus();
        }
      },
      [blurFromTextareaName, textarea, dispatch],
    );

    const memoizedAddChecklistButton = useMemo(
      () => (
        <Button
          dense
          icon='plus'
          type='action'
          color='default'
          view='outlined'
          text='Добавить чеклист'
          disabled={isDisabledWhenNoBBIntegration}
          onClick={setIsAddCheckListToggleHandler}
          className='w-fit'
        />
      ),
      [isDisabledWhenNoBBIntegration, setIsAddCheckListToggleHandler],
    );

    const memoizedShowPreviewButton = useMemo(
      () => (
        <Button
          dense
          type='action'
          color='default'
          view='outlined'
          onClick={modalToggle}
          text='Показать превью'
          className='!flex mr-4 w-[11rem]'
        />
      ),
      [modalToggle],
    );

    // eslint-disable-next-line
    const memoizedScriptButton = useMemo(
      () => (
        <Button
          dense
          type='action'
          color='default'
          view='outlined'
          icon='property'
          text='Добавить скрипт'
          className='!flex mr-4'
        />
      ),
      [],
    );

    const memoizedDialog = useMemo(
      () => (
        <Dialog
          type='default'
          view='raised'
          color='default'
          isOpen={isOpen}
          backdropOpacity={40}
          title='Превью задачи'
          onClose={modalToggle}
          text={
            isOpen ? (
              <TaskPreview
                taskName={taskName}
                taskType={taskType}
                taskDescription={taskDescription}
                taskCheckList={checklistItems}
              />
            ) : (
              <span />
            )
          }
          className='whitespace-pre-line break-words report-preview'
        />
      ),
      [isOpen, modalToggle, taskName, taskType, taskDescription, checklistItems],
    );

    const typeOfTemplate =
      whenSend === SEND_TIME.CLIENTS
        ? TYPE_OF_TEMPLATE.CLIENTS
        : whenSend === SEND_TIME.EVENT &&
          selectedEventType === EVENTS_TYPES.TELEPHONY &&
          selectedTelephonyType === TELEPHONY_EVENTS.MISSED_CALL
        ? TYPE_OF_TEMPLATE.MISSED_CALLS
        : whenSend === SEND_TIME.EVENT &&
          selectedEventType === EVENTS_TYPES.BB_INTEGRATION &&
          selectedBBIntegrationType === BB_INTEGRATION_EVENTS.BAD_FEEDBACK
        ? TYPE_OF_TEMPLATE.BAD_REVIEW
        : whenSend === SEND_TIME.EVENT && selectedEventType === EVENTS_TYPES.CRM
        ? TYPE_OF_TEMPLATE.CRM
        : TYPE_OF_TEMPLATE.COMMON;

    const hideSuccessToastHandler = useCallback(() => {
      dispatch(clearSuccessTP());
    }, [dispatch]);

    const hideErrorToastHandler = useCallback(() => {
      dispatch(clearErrorsTP());
    }, [dispatch]);

    const setReportRequireHandler = (event: ChangeEvent<HTMLInputElement>) => {
      const { checked } = event.target;
      dispatch(
        setTaskData({
          data: {
            ...data,
            report: checked,
          },
        }),
      );
    };

    return (
      <div className='xl:grid xl:grid-cols-[1fr_32%]'>
        <div className='flex flex-col h-full max-w-[47rem] w-full'>
          {isError ? (
            <ErrorMessage />
          ) : (
            <>
              <TaskPathLinks
                id={id}
                isLoading={isLoading}
                taskTypeToPath={taskTypeToPath}
                selectedFilialAccId={selectedFilial?.accId || ''}
                className='mb-10'
              />
              {id ? (
                <p className='font-medium text-h3_body text-darkGray tracking-[0.004em] leading-8 mb-1 min-h-[2rem]'>
                  {`#${id}`}
                </p>
              ) : null}
              <H1 className='mb-4 tracking-[-0.01em]'>
                {!isLoading ? (id ? `Задача` : 'Новая задача') : 'Задача...'}
              </H1>
              {isLoading && !isError ? (
                <Loader className='grow' />
              ) : (
                <>
                  <div className='flex items-center mb-10'>
                    <ToggleSwitch
                      checked={isActive}
                      onChangeHandler={taskStatusToggle}
                      name={TASK_DATA_KEY_NAMES.IS_ACTIVE}
                      className='mr-4'
                    />
                    <div>
                      <p className='font-medium text-blackText m-0'>Действующая задача</p>
                    </div>
                  </div>
                  <p className='mb-6 text-gray2 tracking-[0.0275em]'>
                    Обязательные поля отмечены звёздочкой
                    <span className='text-[red] ml-[0.2em]'>*</span>
                  </p>
                  <div className='flex flex-col'>
                    <TaskDescription
                      type='task'
                      nameIsRequired
                      taskName={taskName}
                      descriptionIsRequired
                      taskDescription={taskDescription}
                      setTaskNameHandler={setTaskNameHandler}
                      disabled={isDisabledWhenNoBBIntegration}
                      templateInputPlaceholder='Опишите задачу'
                      onTextareaBlurHandler={onTextareaBlurHandler}
                      templateInputName={TASK_DATA_KEY_NAMES.TASK_NAME}
                      setTaskDescriptionHandler={setTaskDescriptionHandler}
                      templateDescription={TASK_DATA_KEY_NAMES.TASK_DESCRIPTION}
                      textareaValueMaxLength={Number(CONFIG.TASK_DESCRIPTION_MAX_LENGTH)}
                      templateTextareaPlaceholder='Детали и описание ожидаемого результата'
                      className='mb-8 max-w-[47rem]'
                    />
                    <TaskType selectedTaskType={taskType} className='mb-8 max-w-[47rem]' />
                    <div className='mb-8'>
                      <div className='flex items-baseline'>
                        <p className='text-black text-h3Mobile mb-4 mr-2'>
                          Когда создавать задачу?
                        </p>
                        <IconWithTooltips
                          tooltips='whenSendRegularTasks'
                          className='flex items-center'
                        />
                      </div>
                      <DropdownWithIcon
                        items={sendTime}
                        selectedItem={whenSend}
                        callBack={selectWhenSend}
                        className='w-full max-w-[47rem] when_send_dropdown'
                      />
                    </div>
                    {whenSend === SEND_TIME.REGULAR ? (
                      <RegularBlock
                        frequency={frequency}
                        sendingTime={sendingTime}
                        setWhenTime={setWhenTime}
                        setFrequency={setFrequency}
                        sendWeekDays={sendWeekDays}
                        monthSendDays={monthSendDays}
                        setWhenSendDay={setWhenSendDay}
                        selectedSendingPeriod={sendingPeriod}
                        selectSendingPeriod={selectSendingPeriod}
                        sendOnLastDayOfMonth={sendOnLastDayOfMonth}
                        sortMonthSendDays={sortMonthSendDaysHandler}
                        setMonthSendDayHandler={setMonthSendDayHandler}
                        setSendOnLastDayOfMonth={setSendOnLastDayOfMonthHandler}
                      />
                    ) : null}
                    {whenSend === SEND_TIME.EVENT ? (
                      <EventsBlock
                        staff={staff}
                        visits={visits}
                        className='mb-8'
                        services={services}
                        isSuccess={isSuccess}
                        newStaffValue={newStaffValue}
                        clientDelayed={clientDelayed}
                        commercialTargetProps='_blank'
                        discountAmount={discountAmount}
                        isBBIntegrated={isBBIntegrated}
                        selectedCrmType={selectedCrmType}
                        newServicesValue={newServicesValue}
                        selectedEventType={selectedEventType}
                        setDiscountHandler={setDiscountHandler}
                        categoriesDataList={categoriesDataList}
                        missedCallsPercents={missedCallsPercents}
                        selectedYclientsType={selectedYclientsType}
                        selectedTelephonyType={selectedTelephonyType}
                        setClientDelayHandler={setClientDelayHandler}
                        hrefForCommercial={CONFIG.BB_LANDING_PAGE_LINK}
                        selectedFilialAccId={selectedFilial?.accId || ''}
                        selectedBBIntegrationType={selectedBBIntegrationType}
                        selectedWorkStatisticsType={selectedWorkStatisticsType}
                        setSelectedEventTypeHandler={setSelectedEventTypeHandler}
                        setMissedCallsPercentsHandler={setMissedCallsPercentsHandler}
                        thresholdWhatsappResponseRate={thresholdWhatsappResponseRate}
                        setSelectedYclientsTypeHandler={setSelectedYclientsTypeHandler}
                        thresholdLowEnrolledPercentage={thresholdLowEnrolledPercentage}
                        setSelectedTelephonyEventHandler={setSelectedTelephonyEventHandler}
                        setSelectedCrmTypeHandler={setSelectedCrmTypeHandler}
                        setSelectedBBIntegrationTypeHandler={setSelectedBBIntegrationTypeHandler}
                        setSelectedWorkStatisticsTypeHandler={setSelectedWorkStatisticsTypeHandler}
                        setThresholdWhatsappResponseRateHandler={
                          setThresholdWhatsappResponseRateHandler
                        }
                        setThresholdLowEnrolledPercentageHandler={
                          setThresholdLowEnrolledPercentageHandler
                        }
                      />
                    ) : null}
                    {whenSend === SEND_TIME.CLIENTS ? (
                      <ClientsBlock
                        staff={staff}
                        visits={visits}
                        className='mb-8'
                        services={services}
                        lateReply={lateReply}
                        notRecord={notRecord}
                        isSuccess={isSuccess}
                        sendingTime={sendingTime}
                        setWhenTime={setWhenTime}
                        newStaffValue={newStaffValue}
                        daysAfterVisit={daysAfterVisit}
                        setSelectedEvent={setClientsEvent}
                        newServicesValue={newServicesValue}
                        categoriesDataList={categoriesDataList}
                        selectedClientEvent={selectedClientEvent}
                        daysWithoutVisiting={daysWithoutVisiting}
                        setLateReplyLimit={setLateReplyLimitHandler}
                        setNotRecordLimit={setNotRecordLimitHandler}
                        setNoVisitsDaysHandler={setNoVisitsDaysHandler}
                        setDaysAfterVisitsHandler={setDaysAfterVisitsHandler}
                        selectedFilialAccId={selectedFilial?.accId || ''}
                      />
                    ) : null}
                    <ReportRequire
                      checked={report}
                      name={TASK_DATA_KEY_NAMES.REPORT_REQUIRED}
                      onChangeHandler={setReportRequireHandler}
                      className='my-10'
                    />
                    <TaskAdditionalInfo
                      forMobile
                      typeOfTemplate={typeOfTemplate}
                      setKeyInTextarea={setKeyInTextarea}
                      className='flex lg:hidden flex-col mb-4'
                    />
                    <div className='flex justify-between mb-8'>
                      <div className='flex'>
                        {memoizedShowPreviewButton}
                        {!isAddChecklist ? memoizedAddChecklistButton : null}
                      </div>
                      {/* {memoizedScriptButton} */}
                    </div>
                  </div>
                  {isAddChecklist ? (
                    <CheckListBlock
                      checklistItems={checklistItems}
                      addChecklistItem={addChecklistItem}
                      newChecklistItemName={newChecklistItemName}
                      editCheckListItemHandler={editCheckListItemValue}
                      deleteChecklistItemHandler={deleteChecklistItemHandler}
                      setNewChecklistNameHandler={setNewChecklistNameHandler}
                      setIsAddChecklistHandler={setIsAddCheckListToggleHandler}
                    />
                  ) : null}
                  <div className='mt-16'>
                    <Button
                      dense
                      type='action'
                      view='filled'
                      color='success'
                      icon='small-tick'
                      text='Сохранить шаблон'
                      onClick={saveTaskHandler}
                      loading={isLoading || isSaving}
                      disabled={isDisabledWhenNoBBIntegration || isTaskNoValidToSave || isSaving}
                    />
                  </div>
                  <ToastsMessages
                    errorMessage={errorToastMessage || taskErrorMessage}
                    isShowErrorToast={isSavingError || isCopyTasksError}
                    successMessage={successToastMessage || taskSuccessMessage}
                    isShowSuccessToast={isSavingSuccess || isCopyTasksSuccess}
                    clearErrorCallback={hideErrorToastHandler}
                    clearSuccessStatusCallback={hideSuccessToastHandler}
                  />
                  {memoizedDialog}
                </>
              )}
            </>
          )}
        </div>
        <div className='hidden xl:flex flex-col ml-6'>
          {id && accounts.length > 1 ? (
            <TaskCopy
              dense
              disabled={accounts.length <= 1}
              saveTemplateHandler={saveTaskHandler}
              className='flex xl:justify-end ml-4 mb-10'
            />
          ) : null}
          <TaskAdditionalInfo
            forMobile={false}
            typeOfTemplate={typeOfTemplate}
            setKeyInTextarea={setKeyInTextarea}
            className='h-full'
          />
        </div>
      </div>
    );
  },
);
