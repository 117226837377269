import { emptyTaskData } from '@data/index';
import { TTaskInitialState } from '@redux/Task/zod';

export const initialState: TTaskInitialState = {
  retries: 1,
  isError: false,
  selectionEnd: 0,
  isSaving: false,
  isLoading: false,
  isSuccess: false,
  newStaffName: '',
  isRetries: false,
  selectionStart: 0,
  staffNewValue: '',
  servicesNewValue: '',
  isSavingError: false,
  isAccessDenied: false,
  isSavingSuccess: false,
  newChecklistItemName: '',
  blurFromTextareaName: '',
  errorToastMessage: '',
  successToastMessage: '',
  categoriesDataList: {},
  data: emptyTaskData,
  rejectedFilialsToCopy: [],
  filialsToCopy: [],
  isCopyTasksLoading: false,
  isCopyTasksSuccess: false,
  isCopyTasksError: false,
  //
  isRefresh: false,
  isTaskSwitching: false,
  isTaskSwitchingError: false,
  isTaskSwitchingSuccess: false,
  isDeleting: false,
  isDeletingError: false,
  isDeletingSuccess: false,
  isGettingStaffListError: false,
  taskIdInProgress: '',
  taskInfoData: [],
  sortedTasksList: [],
};
