import React, { memo, useCallback } from 'react';
//
import { useAppDispatch } from '@hooks/index';
import { FormInput } from '@blocks/formInput';
import { setAppDrawerData, setShowAppDrawer } from '@redux/App/slice';
import { TAddBalloonOnBlur, TKeyboardClickEvent, TOnChangeHandler } from '@shared/types';

interface IRecipientBlockProps {
  /**
   * Массив тел. номеров для получения отчетов в telegram
   * @param {string[]}
   */
  telegramRecipients: string[];
  /**
   * Массив тел. номеров для получения отчетов в whatsapp
   * @param {string[]}
   */
  whatsappRecipients: string[];
  /**
   * Значение инпута для ввода нового телефона в блоке telegram
   * @param {string}
   */
  telegramRecipientsNewValue: string;
  /**
   * Значение инпута для ввода нового телефона в блоке whatsapp
   * @param {string}
   */
  whatsappRecipientsNewValue: string;
  /**
   * Callback для добавления нового номера на событие blur
   * @param {TAddBalloonOnBlur}
   */
  addBalloonOnBlur: TAddBalloonOnBlur;
  /**
   * Callback для добавления нового номера по нажатию на Enter
   * @param {TKeyboardClickEvent}
   */
  addBalloonByEnterKey: TKeyboardClickEvent;
  /**
   * Обработчик изменений в инпутах для ввода новых телефонов
   */
  newBalloonValueOnChangeHandler: TOnChangeHandler;
  /**
   * Callback для очистки инпутов для ввода новых телефонов
   * @param {(name: string) => void}
   */
  clearNewBalloonValueHandler: (name: string) => void;
  /**
   * Callback для удаления телефона из списка
   * @param {(value: string, keyName: string) => () => void}
   */
  deleteBalloon: (value: string, keyName: string) => () => void;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const RecipientBlock = memo(
  ({
    deleteBalloon,
    addBalloonOnBlur,
    telegramRecipients,
    whatsappRecipients,
    addBalloonByEnterKey,
    telegramRecipientsNewValue,
    whatsappRecipientsNewValue,
    clearNewBalloonValueHandler,
    newBalloonValueOnChangeHandler,
    className = '',
  }: IRecipientBlockProps) => {
    const dispatch = useAppDispatch();
    const onOpenDrawerHandler = useCallback(
      ({ title, description }: { title: string; description: string }) =>
        () => {
          dispatch(setShowAppDrawer(true));
          dispatch(setAppDrawerData({ title, description }));
        },
      [dispatch],
    );

    return (
      <div className={className}>
        <p className='font-medium text-black text-h3_body leading-8 tracking-[0.004em] mb-4'>
          Получатели в WhatsApp
        </p>
        <FormInput
          noLabel
          variant='staff'
          typeOfValueIsNumber
          value={whatsappRecipients}
          inputId='whatsappRecipients'
          deleteBalloon={deleteBalloon}
          addBalloonOnBlur={addBalloonOnBlur}
          placeholder='Введите номер телефона'
          newValue={whatsappRecipientsNewValue}
          clearNewValue={clearNewBalloonValueHandler}
          addBalloonByEnterKey={addBalloonByEnterKey}
          setNewValue={newBalloonValueOnChangeHandler}
          className='w-full max-w-[47rem]'
        />
        <div className='flex items-center mb-4 mt-10'>
          <p className='font-medium text-black text-h3_body leading-8 tracking-[0.004em]'>
            Получатели в Telegram
          </p>
        </div>
        <FormInput
          noLabel
          variant='staff'
          typeOfValueIsNumber
          value={telegramRecipients}
          inputId='telegramRecipients'
          deleteBalloon={deleteBalloon}
          addBalloonOnBlur={addBalloonOnBlur}
          placeholder='Введите телеграмм id'
          newValue={telegramRecipientsNewValue}
          clearNewValue={clearNewBalloonValueHandler}
          addBalloonByEnterKey={addBalloonByEnterKey}
          setNewValue={newBalloonValueOnChangeHandler}
          className='w-full max-w-[47rem]'
        />
      </div>
    );
  },
);
