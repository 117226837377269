import React, { memo, useEffect } from 'react';
//
import { PayTemplate } from '@templates/pay';
import { NoAccess } from '@components/noAccess';
import { getPayPageData } from '@redux/Pay/thunk';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { getAccountsData, getPayPageState } from '@redux/selectors';

const PayPage = memo(() => {
  const dispatch = useAppDispatch();
  const { selectedFilial, isLoading: isAccountsLoading } = useAppSelector(getAccountsData);
  const { data, isError, isLoading, isRetries, isAccessDenied } = useAppSelector(getPayPageState);

  // Запрашивает данные страницы оплаты при первом рендеренге и при смене филиала в меню
  useEffect(() => {
    if (selectedFilial?.accId) {
      dispatch(getPayPageData());
    }
  }, [dispatch, selectedFilial?.accId]);

  // Запрашивает данные страницы оплаты повторно при ошибке токена, без задержки выдает ошибку 403
  useEffect(() => {
    if (isRetries) {
      setTimeout(() => dispatch(getPayPageData()), 100);
    }
  }, [dispatch, isRetries]);

  return (
    <>
      {isAccessDenied ? <NoAccess /> : null}
      {!isAccessDenied ? (
        <PayTemplate isError={isError} isLoading={isLoading || isAccountsLoading} data={data} />
      ) : null}
    </>
  );
});

export default PayPage;
