import { END_POINTS_URL } from '@const/apiConstants';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ConfigENV, REQUEST_STATUS, REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';

import { IStorageApi, storageDb } from './storageApi';

export interface IHttpService {
  setRequestInterceptor: () => void;
  setResponseInterceptor: () => void;
  api<T>(options: AxiosRequestConfig): Promise<AxiosResponse<T>>;
}

/**
 * Class выполняет запросы с переданными данными
 * так же настраивает axios interceptors
 * с их помощью осуществляет формирования параметров для запроса
 * и  обновления токена
 * @module HttpService
 */

class HttpService implements IHttpService {
  private static _instance: IHttpService;

  /**
   * Class storageDb предоставляет методы для сохранения данных в хранилищах браузера
   * @private
   * @type {IStorageApi}
   */
  private storage: IStorageApi;

  /**
   * Переменная для хранения количества попыток обновления токена
   * @private
   * @type {number}
   */
  private retries: number;

  /**
   * Инстанс axios
   * @private
   * @type {AxiosInstance}
   * @readonly
   */
  private readonly axiosInstance: AxiosInstance;

  /**
   * Получаем из параметров storage и записываем в приватную переменную
   * @constructor
   * @param storage {IStorageApi}
   */
  constructor(storage: IStorageApi) {
    if (HttpService._instance) {
      throw new Error("Singleton classes can't be instantiated more than once.");
    }
    this.axiosInstance = axios.create({ baseURL: ConfigENV.BASE_URL });
    this.storage = storage;
    this.retries = 3;
    this.setRequestInterceptor();
    this.setResponseInterceptor();
  }

  /**
   * Static method to get the singleton instance of the StorageApi class.
   * @static
   * @param {IStorageApi} storage - Class storageDb предоставляет методы для сохранения данных в хранилищах браузера.
   * @returns {HttpService} The singleton instance of the HttpService class.
   */
  public static getInstance(storage: IStorageApi) {
    if (!HttpService._instance) {
      HttpService._instance = new HttpService(storage);
    }
    return HttpService._instance;
  }

  /**
   * Axios request interceptor
   * он перехватывает запрос  и добавляет в него токен и параметры user_id и acc_id
   * @method setRequestInterceptor
   * @return {void}
   */
  setRequestInterceptor(): void {
    this.axiosInstance.interceptors.request.use(
      config => {
        const { accessToken, userId, accId } = this.storage.getRequestData();
        config.headers.Authorization = `Bearer ${accessToken}`;

        const oldConfigParams = { ...config.params };

        config.params =
          oldConfigParams.acc_id || oldConfigParams.user_id
            ? config.params
            : { ...config.params, user_id: userId, acc_id: accId };
        return config;
      },
      error => {
        return Promise.reject(error);
      },
    );
  }

  /**
   * Axios request interceptor
   * перехватывает ответ от сервера проверяет есть ли ошибка и если ошибка 403 и статус 'Access denied'
   * осуществляет запрос на обновление токена и в случае успеха записывает в хранилище браузера новый токен
   * @method setResponseInterceptor
   * @return {void}
   */
  setResponseInterceptor(): void {
    this.axiosInstance.interceptors.response.use(
      config => config,
      error => {
        const requestData = this.storage.getRequestData();
        if (
          error.response.status === REQUEST_STATUS.TOKEN_EXPIRED &&
          error.response.data.status !== REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED
        ) {
          if (this.retries > 0) {
            this.axiosInstance
              .request({
                url: `${END_POINTS_URL.UPDATE_TOKEN}?token=${requestData.accessToken}`,
              })
              .then(response => {
                this.storage.setToken(response.data.access_token);
                this.retries = 3;
                // error.config.headers.Authorization = `Bearer ${response.data.access_token}`;
                // instance.request(error.config);
              })
              .catch(_error => Promise.reject(_error));
            this.retries--;
          }
        }
        return Promise.reject(error);
      },
    );
  }

  api<T>(options: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    return this.axiosInstance.request<T>(options);
  }
}

export const httpService = HttpService.getInstance(storageDb);
