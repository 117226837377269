import {
  TTelegramAuthData,
  TTelegramPinAuthResponse,
  TTelegramAuthResponseData,
} from '@models/Auth';

export const authMappers = {
  widgetAuthData: (data: TTelegramAuthResponseData): TTelegramAuthData => {
    const { id, last_name: lastName, username, photo_url: photoUrl, first_name: firstName } = data;

    return {
      id,
      firstName,
      lastName,
      username,
      photoUrl,
    };
  },
  botAuthData: (data: TTelegramPinAuthResponse): TTelegramAuthData => {
    const {
      username,
      user_id: id,
      photo_url: photoUrl,
      last_name: lastName,
      first_name: firstName,
    } = data;
    return {
      id,
      firstName,
      lastName,
      username,
      photoUrl,
    };
  },
};
