import { log } from '@helpers/index';
import { authApi } from '@api/auth/authApi';
import { storageDb } from '@api/storageApi';
import { authMappers } from '@redux/Auth/mappers';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { AUTH_RESPONSE_KEYS, REJECT_RESPONSE_KEY, AUTH_ERROR_RESPONSE_KEY } from '@api/types';
import {
  TLoginData,
  AuthResponseSchema,
  TelegramPinAuthResponse,
  TTelegramAuthResponseData,
  TelegramAuthResponseDataSchema,
} from '@models/Auth';

type TLoginRejectValue = { rejectValue: string };

export const login = createAsyncThunk<
  TLoginData | void,
  TTelegramAuthResponseData,
  TLoginRejectValue
>('auth/login', (data, { rejectWithValue }) => {
  return authApi
    .loginUser(data)
    .then(response => {
      if (AUTH_ERROR_RESPONSE_KEY.MESSAGE in response.data) {
        throw new Error(String(response.data.message));
      }
      if (AUTH_RESPONSE_KEYS.AUTH in response.data) {
        const parsedData = TelegramAuthResponseDataSchema.safeParse(response.data);

        if (!parsedData.success) {
          log('@@auth/login pars error', parsedData.error.format());
          // throw new Error('Something went wrong');
        }
        const { auth, user_id, access_token } = response.data;
        const mappedUserData = authMappers.widgetAuthData(data);

        if (response.data.auth) {
          storageDb.setAuthData({ auth, token: access_token, authUserData: mappedUserData });
        }
        return {
          accessToken: access_token,
          userId: user_id,
          auth,
          authUserData: mappedUserData,
        };
      }
    })
    .catch(error => rejectWithValue(error.response.status));
});

export const getPinData = createAsyncThunk<boolean | void, string, TLoginRejectValue>(
  'auth/getPinData',
  (data, { rejectWithValue }) => {
    return authApi
      .getPin(data)
      .then(response => {
        if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
          throw new Error(response.data.status);
        }
        if (REJECT_RESPONSE_KEY.OK in response.data && response.data.ok) {
          return true;
        }
      })
      .catch(error => rejectWithValue(error.response.data));
  },
);

export const loginUserByPinThunk = createAsyncThunk<
  TLoginData | void,
  { userId: number; pin: number },
  TLoginRejectValue
>('auth/loginUser', (data, { rejectWithValue }) => {
  return authApi
    .loginUserByPin(data)
    .then(response => {
      if (REJECT_RESPONSE_KEY.OK in response.data && !response.data.ok) {
        throw new Error(response.data.status);
      }
      if (AUTH_RESPONSE_KEYS.AUTH in response.data) {
        const parsedData = TelegramPinAuthResponse.safeParse(response.data);

        if (!parsedData.success) {
          log('@@auth/login pars error', parsedData.error.format());
          // throw new Error(parsedData.error.toString());
        } else {
          const { auth, user_id, access_token } = parsedData.data;
          const mappedUserData = authMappers.botAuthData(parsedData.data);

          if (parsedData.data.auth) {
            storageDb.setAuthData({ auth, token: access_token, authUserData: mappedUserData });
          }
          return {
            accessToken: access_token,
            userId: user_id,
            auth,
            authUserData: mappedUserData,
          };
        }
      }
    })
    .catch(error => rejectWithValue(error.response.data));
});

export const updateToken = createAsyncThunk<
  Omit<TLoginData, 'authUserData'> | void,
  string,
  TLoginRejectValue
>('auth/updateToken', (data, { rejectWithValue }) => {
  return authApi
    .updateToken(data)
    .then(response => {
      if (AUTH_ERROR_RESPONSE_KEY.MESSAGE in response.data) {
        throw new Error(String(response.data.message));
      }
      if (AUTH_RESPONSE_KEYS.AUTH in response.data) {
        const parsedData = AuthResponseSchema.safeParse(response.data);

        if (!parsedData.success) {
          log('@@auth/updateToken pars error', parsedData.error.format());
          // throw new Error(parsedData.error.toString());
        } else {
          const { auth, user_id, access_token } = parsedData.data;

          if (parsedData.data.auth) {
            storageDb.setAuth(auth);
            storageDb.setUserId(user_id);
            storageDb.setToken(access_token);
          }
          return {
            accessToken: access_token,
            userId: user_id,
            auth,
          };
        }
      }
    })
    .catch(error => rejectWithValue(error.response.status));
});
