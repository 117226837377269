import React, { useState } from 'react';
//
import { Menu } from '@uikit/Menu';
import { createPortal } from 'react-dom';
import { Overlay } from '@atoms/overlay';
import { Icon } from '@blueprintjs/core';
import { MenuItem } from '@uikit/Menu/MenuItem';
import { TInstructionData } from '@const/common';
import { useWindowWidth } from '@hooks/useWindowWidth';
import { VideoInstructionModal } from '@blocks/videoInstructionModal';
import { VideoInstructionButton } from '@blocks/videoInstructionButton';

type TVideoInstructionWithDropdownProps = {
  /**
   * Данные для отображения инструкций
   * @param {TInstructionData[]}
   */
  instructionData: TInstructionData[];
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const VideoInstructionWithDropdown = ({
  instructionData,
  className = '',
}: TVideoInstructionWithDropdownProps) => {
  const width = useWindowWidth();

  const [isShowModal, setIsShowModal] = useState(false);
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [modalData, setModalData] = useState<TInstructionData>({
    startTime: '1',
    videoLink: '',
    headerText: '',
  });

  const isDropdown = instructionData.length > 1;

  const dropdownToggle = () => {
    setIsShowDropdown(prevState => !prevState);
  };

  const modalToggle = (data: TInstructionData) => {
    setIsShowModal(prevState => !prevState);
    setModalData(data);
  };

  const closeDropdown = () => {
    setIsShowDropdown(false);
  };

  const videoInstructionButtonHandler = (data: TInstructionData) => () => {
    if (isDropdown && !isShowDropdown) {
      dropdownToggle();
    } else {
      closeDropdown();
      modalToggle(data);
    }
  };

  const menuItemsToRender = isDropdown
    ? instructionData.map(instruction => (
        <MenuItem
          key={instruction.headerText}
          text={<span>{instruction.headerText}</span>}
          onClick={videoInstructionButtonHandler(instruction)}
        />
      ))
    : null;

  return (
    <div className={`${width > 640 ? 'relative' : ''} ${className}`}>
      <VideoInstructionButton
        isShowRightIcon={isDropdown}
        onClickCallback={videoInstructionButtonHandler(instructionData[0])}
        buttonText='Видео-инструкция'
      />
      {isShowDropdown ? (
        <div
          className={`absolute z-[53] ${
            width < 641 ? 'bg-white top-0 right-0 bottom-0 left-0 p-4' : 'top-12 right-0'
          }`}>
          {width < 641 ? (
            <div className='flex items-start justify-end h-9'>
              <button
                onClick={closeDropdown}
                className='bg-none border-none'
                aria-label='closeDropdown'>
                <Icon icon='cross' size={24} color='#4A4B57' />
              </button>
            </div>
          ) : null}
          <Menu
            view={width > 640 ? 'raised' : ''}
            className={Object.entries(instructionData).length === 1 ? `one_item_ul` : ''}>
            {menuItemsToRender}
          </Menu>
        </div>
      ) : null}
      {isShowDropdown
        ? createPortal(
            <Overlay onClickCallback={dropdownToggle} className='z-[52]' />,
            document.body,
          )
        : null}
      {isShowModal
        ? createPortal(
            <VideoInstructionModal
              isOpen={isShowModal}
              videoLink={modalData.videoLink}
              startTime={modalData.startTime}
              headerText={modalData.headerText}
              closeModalHandler={() => setIsShowModal(false)}
            />,
            document.body,
          )
        : null}
    </div>
  );
};
