import { PATH_NAMES } from '@const/httpConst';
import { getWeekDaysFromArray } from '@helpers/index';
import { TASK_EVENT_NAMES, TGetResponseWithData } from '@api/types';
import { TTaskCardData, TTaskData, TTaskDataResponse, TTaskInfo } from '@models/Tasks';
import {
  TASK_TYPE,
  SEND_TIME,
  CRM_EVENTS,
  SEND_TIMES,
  EVENTS_TYPES,
  CLIENTS_EVENTS,
  MONTH_SEND_DAYS,
  SENDING_PERIODS,
  TELEPHONY_EVENTS,
  BB_INTEGRATION_EVENTS,
} from '@const/task';

const getNumberArrayFromString = (string: string): number[] => {
  return string.split(',').map(item => Number(item));
};

const transformData = (stringArray: string[], fallReturn: [] | null = null): number[] | null => {
  return stringArray.length ? stringArray.map(item => Number(item)) : fallReturn;
};

export const mappers = {
  responseToStore: ({
    frequency,
    week,
    month,
    event,
    last_visits,
    not_record,
    late_reply,
    id,
    mark,
    active,
    title,
    time,
    body,
    checklist,
    staff,
    visits,
    services,
    proof_of_work,
    days_after_record,
  }: TTaskDataResponse): TTaskData => {
    const whenSend =
      frequency || week || month
        ? SEND_TIME.REGULAR
        : event
        ? SEND_TIME.EVENT
        : last_visits || not_record || late_reply || days_after_record
        ? SEND_TIME.CLIENTS
        : '';

    const selectedClientEvent = last_visits
      ? CLIENTS_EVENTS.LOST
      : days_after_record
      ? CLIENTS_EVENTS.AFTER_VISIT
      : not_record
      ? CLIENTS_EVENTS.NOT_RECORD
      : late_reply
      ? CLIENTS_EVENTS.LATE_REPLY
      : '';

    const sendingPeriod =
      frequency === 1
        ? SENDING_PERIODS.DAY
        : frequency && frequency > 1
        ? SENDING_PERIODS.EVERY_FEW_DAYS
        : week
        ? SENDING_PERIODS.WEEK
        : month
        ? SENDING_PERIODS.MONTH
        : '';

    const monthSendDays = month
      ? month.includes(MONTH_SEND_DAYS.LAST_DAY)
        ? String(month.slice(0, month.length - 1))
        : String(month.join(','))
      : String(MONTH_SEND_DAYS.FIRST_DAY);

    const selectedEventType =
      event === TASK_EVENT_NAMES.MISSED_CALL
        ? EVENTS_TYPES.TELEPHONY
        : event === TASK_EVENT_NAMES.BAD_REVIEW
        ? EVENTS_TYPES.BB_INTEGRATION
        : event === TASK_EVENT_NAMES.RECORD_DONE
        ? EVENTS_TYPES.CRM
        : '';
    const sendOnLastDayOfMonth = month ? month.includes(MONTH_SEND_DAYS.LAST_DAY) : false;
    const daysWithoutVisiting = last_visits ? String(last_visits) : '1';
    const selectedTelephonyType =
      event === TASK_EVENT_NAMES.MISSED_CALL ? TELEPHONY_EVENTS.MISSED_CALL : event || '';
    const selectedCrmType =
      event === TASK_EVENT_NAMES.RECORD_DONE ? CRM_EVENTS.RECORD_DONE : event || '';

    return {
      id: String(id),
      isActive: active,
      taskType: mark?.type || TASK_TYPE.CASE,
      whenSend,
      selectedClientEvent,
      notRecord: String(not_record || ''),
      lateReply: String(late_reply || ''),
      sendingPeriod,
      frequency: frequency || 1,
      sendingTime: time || SEND_TIMES.MIDNIGHT,
      sendWeekDays: getWeekDaysFromArray(week),
      monthSendDays,
      sendOnLastDayOfMonth,
      daysWithoutVisiting,
      selectedEventType,
      selectedTelephonyType,
      selectedYclientsType: '',
      selectedCrmType,
      discountAmount: '',
      clientDelayed: '',
      taskName: title,
      taskDescription: body,
      isAddChecklist: !!checklist?.length,
      checklistItems: checklist || [],
      selectedBBIntegrationType:
        event === TASK_EVENT_NAMES.BAD_REVIEW ? BB_INTEGRATION_EVENTS.BAD_FEEDBACK : '',
      selectedWorkStatisticsType: '', // нет данных
      missedCallsPercents: '', // нет данных
      thresholdWhatsappResponseRate: '', // нет данных
      thresholdLowEnrolledPercentage: '', // нет данных
      daysAfterVisit: String(days_after_record || ''),
      staff: staff ? staff.map(item => String(item)) : [],
      visits: visits ? visits.map(item => String(item)) : [],
      services: services ? services.map(item => String(item)) : [],
      report: proof_of_work,
    };
  },
  storeToRequest: ({
    id,
    staff,
    visits,
    report,
    whenSend,
    services,
    taskType,
    isActive,
    taskName,
    notRecord,
    frequency,
    lateReply,
    sendingTime,
    sendWeekDays,
    daysAfterVisit,
    monthSendDays,
    sendingPeriod,
    checklistItems,
    isAddChecklist,
    selectedCrmType,
    taskDescription,
    selectedEventType,
    selectedClientEvent,
    daysWithoutVisiting,
    sendOnLastDayOfMonth,
    selectedTelephonyType,
    selectedBBIntegrationType,
  }: TTaskData): TTaskDataResponse => {
    const isSendTimeRegular = whenSend === SEND_TIME.REGULAR;
    const isSendingPeriodDay = sendingPeriod === SENDING_PERIODS.DAY;
    const isSendingPeriodEveryFewDays = sendingPeriod === SENDING_PERIODS.EVERY_FEW_DAYS;
    const isSendingPeriodEveryWeek = sendingPeriod === SENDING_PERIODS.WEEK;
    const isSendingPeriodEveryMonth = sendingPeriod === SENDING_PERIODS.MONTH;

    const isSendTimeEvent = whenSend === SEND_TIME.EVENT;
    const isSelectedEventTypeTelephony = selectedEventType === EVENTS_TYPES.TELEPHONY;
    const isSelectedTelephonyTypeMissedCall =
      selectedTelephonyType === TELEPHONY_EVENTS.MISSED_CALL;
    const isSelectedEventTypeTelephonyBBIntegration =
      selectedEventType === EVENTS_TYPES.BB_INTEGRATION;
    const isSelectedBBIntegrationTypeBadFeedback =
      selectedBBIntegrationType === BB_INTEGRATION_EVENTS.BAD_FEEDBACK;

    const isSendTimeClient = whenSend === SEND_TIME.CLIENTS;
    const isSelectedClientEventLost = selectedClientEvent === CLIENTS_EVENTS.LOST;
    const isSelectedClientEventAfterVisit = selectedClientEvent === CLIENTS_EVENTS.AFTER_VISIT;
    const isSelectedClientEventNotRecord = selectedClientEvent === CLIENTS_EVENTS.NOT_RECORD;
    const isSelectedClientEventLateReply = selectedClientEvent === CLIENTS_EVENTS.LATE_REPLY;

    const isCrmEvent = selectedEventType === EVENTS_TYPES.CRM;
    const isSelectedEventTypeRecordDone = selectedCrmType === CRM_EVENTS.RECORD_DONE;

    const isMissedCallTask =
      isSendTimeEvent && isSelectedEventTypeTelephony && isSelectedTelephonyTypeMissedCall;
    const isLostClientTask = isSendTimeClient && isSelectedClientEventLost;
    const isDayAfterVisitTask = isSendTimeClient && isSelectedClientEventAfterVisit;
    const isRecordDoneTask = isCrmEvent && isSelectedEventTypeRecordDone;
    const isBadReviewsTask =
      isSendTimeEvent &&
      isSelectedEventTypeTelephonyBBIntegration &&
      isSelectedBBIntegrationTypeBadFeedback;

    const frequencyData =
      isSendTimeRegular && isSendingPeriodDay ? 1 : isSendingPeriodEveryFewDays ? frequency : null;

    const week =
      isSendTimeRegular && isSendingPeriodEveryWeek
        ? Object.entries(sendWeekDays)
            .map(item => (item[1] ? +item[0] : 0))
            .filter(item => item)
            .sort()
        : null;

    const month =
      isSendTimeRegular && isSendingPeriodEveryMonth
        ? monthSendDays
          ? sendOnLastDayOfMonth
            ? [...getNumberArrayFromString(monthSendDays), MONTH_SEND_DAYS.LAST_DAY]
            : getNumberArrayFromString(monthSendDays)
          : sendOnLastDayOfMonth
          ? [MONTH_SEND_DAYS.LAST_DAY]
          : null
        : null;

    const event = isMissedCallTask
      ? TASK_EVENT_NAMES.MISSED_CALL
      : isBadReviewsTask
      ? TASK_EVENT_NAMES.BAD_REVIEW
      : isRecordDoneTask
      ? TASK_EVENT_NAMES.RECORD_DONE
      : null;

    return {
      id: +id || 0, // id шаблона
      active: isActive, // состояние вкл/выкл
      title: taskName, // название задачи
      body: taskDescription, // описание задачи
      time: !isSendTimeEvent ? sendingTime : null, // время отправки задачи
      mark: taskType ? { type: taskType } : null, // метка задачи client, case, staff
      checklist: isAddChecklist && checklistItems?.length ? checklistItems : null, // список полей чеклиста
      frequency: frequencyData, // Каждые N дней
      week, // Каждую неделю в N день, от 1 понед до 7 воскр
      month, // Каждый месяц в N день, массив от 1 до 31
      event, // (missed_call, bad_review)
      last_visits: isLostClientTask ? Number(daysWithoutVisiting) : null, // последний визит N дней назад
      days_after_record: isDayAfterVisitTask ? Number(daysAfterVisit) : null, // последняя запись N дней назад
      not_record: isSendTimeClient && isSelectedClientEventNotRecord ? Number(notRecord) : null, // Последнее обращение N минут назад
      late_reply: isSendTimeClient && isSelectedClientEventLateReply ? Number(lateReply) : null, // Последний ответ N минут назад
      services:
        isLostClientTask || isRecordDoneTask || isDayAfterVisitTask
          ? transformData(services, null)
          : null,
      staff:
        isLostClientTask || isRecordDoneTask || isDayAfterVisitTask ? transformData(staff) : null,
      visits:
        isLostClientTask || isRecordDoneTask || isDayAfterVisitTask ? transformData(visits) : null,
      proof_of_work: report,
    };
  },
};

export const taskListMappers = {
  responseToStore: (
    data: TGetResponseWithData<TTaskCardData[]>,
    accId: string,
  ): {
    data: TTaskInfo[];
    order: string[];
  } => {
    return {
      data: data.data.map(item => ({
        id: String(item.id),
        active: item.state,
        name: item.title,
        type: item.mark?.type || '',
        isChecklist: false,
        description: '',
        link: `${PATH_NAMES.ONE_TASK}?acc_id=${accId}&task_id=${item.id}`,
        whenSend: item.description,
      })),
      order: data.order,
    };
  },
};
