import React, { memo, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
//
import { TTaskInfo } from '@models/Tasks';
import { useAppSelector } from '@hooks/index';
import { getAppData } from '@redux/selectors';
import { DeleteButton } from '@blocks/deleteButton';
import { ToggleSwitch } from '@blocks/toggleSwitch';
import { DeleteModal } from '@components/deleteModal';
import { TaskTypeChips } from '@blocks/taskTypeChips';
import { TDeleteTask, TOnChangeHandler, TOnclickHandler } from '@shared/types';

interface ICardProps {
  /**
   * Данные для карточки задачи
   * @param {TTaskInfo}
   */
  data: TTaskInfo;
  /**
   * Если true запрос на удаление обрабатывается
   * @param {boolean}
   */
  isDeleting: boolean;
  /**
   * Callback на удаление задачи
   * @param {TDeleteTask}
   */
  deleteTask: TDeleteTask;
  /**
   * Callback на переключение активности задачи
   * @param {TDeleteTask}
   */
  switchTask: TDeleteTask;
  /**
   * Флаг ошибки при удалении
   * @param {boolean}
   */
  isDeletingError: boolean;
  /**
   * Флаг успешного удаления задачи
   * @param {boolean}
   */
  isDeletingSuccess: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
}

export const TaskCard = memo(
  ({
    data,
    deleteTask,
    switchTask,
    isDeleting,
    isDeletingError,
    isDeletingSuccess,
    className = '',
  }: ICardProps) => {
    const { isDragging } = useAppSelector(getAppData);
    const { id, name: taskName, active, link, whenSend, type, isChecklist } = data;

    // состояние модалки подтверждения удаления шаблона
    const [isOpen, setIsOpen] = useState<boolean>(false);

    // Закрывает окно подтверждения удаления шаблона при успешном удалении
    // или при возникновении ошибки
    useEffect(() => {
      if (isDeletingSuccess || isDeletingError) {
        setIsOpen(false);
      }
    }, [isDeletingSuccess, isDeletingError]);

    // открывает модалку на подтверждение удаления шаблона из списка
    const modalToggle: TOnclickHandler = event => {
      event.preventDefault();
      setIsOpen(prevState => !prevState);
    };

    // вызывает коллбак удаления шаблона и передает в него его ID шаблона
    const deleteTaskHandler: TOnclickHandler = event => {
      event.preventDefault();
      deleteTask(id);
    };

    // включает или отключает задачу по клику на переключатель
    const toggleTaskActive: TOnChangeHandler = event => {
      event.preventDefault();
      switchTask(id);
    };

    return (
      <div
        className={`relative bg-white border border-lightGray flex flex-col justify-between font-inter text-blackText p-[0.8rem] pb-[1.625rem] rounded-lg h-[15rem] max-h-[15rem] w-full hover:shadow-btnHoverShadow transition-all ${className}`}>
        <Link
          to={link}
          aria-label={`Задача ${id}`}
          className={`${!isDragging ? 'absolute' : 'hidden'} top-0 right-0 bottom-0 left-0`}
        />
        <div className='flex flex-col grow m-0.5'>
          <div className='flex mb-4'>
            <ToggleSwitch
              name={id}
              checked={active}
              onChangeHandler={toggleTaskActive}
              className='cursor-pointer items-start mr-2 z-[1]'
            />
            <span className='text-darkGray text-body_text'>{id}</span>
          </div>
          <p className='break-words font-medium text-black text-h3Mobile tracking-[0.004em] leading-[1.5rem] overflow-hidden mb-0 h-[3rem]'>
            {taskName}
          </p>
        </div>
        <div className=''>
          <TaskTypeChips type={type} isChecklist={isChecklist} />
          <p className='text-body_text text-grayText mt-[0.875rem] mb-0 overflow-hidden max-w-[93%]'>
            {whenSend}
          </p>
        </div>
        <DeleteButton
          callback={modalToggle}
          className='absolute bottom-[1.525rem] right-[0.975rem]'
        />
        <DeleteModal
          isOpen={isOpen}
          text={taskName}
          isDeleting={isDeleting}
          modalToggleCallback={modalToggle}
          deleteTaskCallback={deleteTaskHandler}
          title={`Вы хотите удалить\n задачу ${id}?`}
        />
      </div>
    );
  },
);
