import { getEUDateFromString } from '@helpers/index';
import { TAccountResponseData, TAccountsData, TFilialData } from '@models/Accounts';

export const accountsMappers = {
  responseToStore: ({ data, count, banner_data }: TAccountResponseData): TAccountsData => {
    const mappedData: TFilialData[] = data.map(item => {
      const rights = Object.entries(item.permissions || {})
        .map(permission => {
          return permission[1] ? permission[0] : '';
        })
        .filter(permission => !!permission);

      return {
        rights,
        name: item.name,
        accId: item.acc_id,
        process: item.process,
        branchId: item.branch_id,
        payPeriod: getEUDateFromString(item.pay_period),
        quantityOfTemplates: item.quantity_of_templates,
        beautyBotIntegration: item.beauty_bot_integration || false,
        payerData: item.payer_data,
      };
    });

    return {
      count,
      data: mappedData,
      bannerData: {
        imageUrl: banner_data?.image_url || '',
        infoUrl: banner_data?.info_link || '',
      },
    };
  },
};
