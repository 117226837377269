import React, { memo } from 'react';
//
import { numWord } from '@helpers/index';

interface IPayCardProps {
  /**
   * Количество месяцев, по этому параметру рассчитывается цена
   * @param {string}
   */
  months: string;
  /**
   * Количество недель в подарок
   * @param {string}
   */
  giftWeeks: string;
  /**
   * Цена за один месяц со скидкой
   * @param {number}
   */
  oneMonthCost: number;
  /**
   * Базовая цена за один месяц
   * @param {number}
   */
  basePrice: number;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
  /**
   * Слушатель события выбора карточки
   * @param {() => void}
   */
  setActiveCard: () => void;
}

export const PayCard = memo(
  ({
    months,
    giftWeeks,
    oneMonthCost,
    className = '',
    setActiveCard,
    basePrice,
  }: IPayCardProps) => {
    // Общая сумма к оплате
    const totalAmount = basePrice * Number(months);

    // Формируем строку с количеством месяцев
    const numberOfMonthText = `${months} ${numWord(Number(months), [
      'месяц',
      'месяца',
      'месяцев',
    ])}`;

    // Формируем строку с количеством недель в подарок
    const numberOfWeeksAsGiftText = `🎁 +${giftWeeks} ${numWord(Number(giftWeeks), [
      'неделя',
      'недели',
      'недель',
    ])}`;

    // Формируем строку с общей суммой к оплате
    const totalAmountText = `итого ${totalAmount} ₽`;

    return (
      <div
        className={`flex flex-col justify-between hover:bg-aquaSpring rounded-lg h-[10.5rem] min-w-[12.875rem] w-full lg:w-fit cursor-pointer ${className}`}
        onClick={setActiveCard}>
        <p className='font-medium text-small_text text-darkGray leading-5 mb-1'>
          {numberOfMonthText}
        </p>
        <p className='font-medium text-h6Mobile text-black leading-8 mb-1'>{`${oneMonthCost} ₽ / мес.`}</p>
        {+months > 1 ? (
          <p className='font-medium text-small_text text-black leading-6'>{totalAmountText}</p>
        ) : null}
        {giftWeeks ? (
          <p className='font-medium text-captionSmall text-green1 m-1'>{numberOfWeeksAsGiftText}</p>
        ) : null}
        {!giftWeeks ? <p className='mb-[2.5625rem]' /> : null}
      </div>
    );
  },
);
