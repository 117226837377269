import React, { useCallback, useMemo } from 'react';
//
import { Status } from '@blocks/colorStatus';
import { useAppDispatch } from '@hooks/index';
import { LABEL_TEXT, TOOLTIPS_TEXT } from '@data/dict';
import { IconWithDrawer } from '@components/iconWithDrawer';
import { setAppDrawerData, setShowAppDrawer } from '@redux/App/slice';

type TFilialPayData = {
  /**
   * Сумма ежемесячной оплаты
   * @param {string}
   */
  amount: string;
  /**
   * ID филиала
   * @param {string}
   */
  branch: string;
  /**
   * Название филиала
   * @param {string}
   */
  filialName: string;
  /**
   * Статус оплаты
   * @param {boolean}
   */
  payStatus: boolean;
  /**
   * До какой даты оплачена подписка
   * @param {string}
   */
  payTillDate: string;
  /**
   * Количество сотрудников в филиале
   * @param {string}
   */
  branchEmployees: string;
  /**
   * Флаг подключения автоплатежа
   * @param {boolean}
   */
  isAutoPayEnable: boolean;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
  /**
   * Опциональный параметр отвечающий за размер скидки в %
   * @param {number}
   * @default 0
   */
  discount?: number;
  /**
   * Параметр показывающий подключена ли интеграция с ББ
   * @param {boolean}
   * @default false
   */
  bbIntegration?: boolean;
};

export const FilialPayData = ({
  amount,
  branch,
  payStatus,
  filialName,
  payTillDate,
  isAutoPayEnable,
  branchEmployees,
  discount = 0,
  className = '',
  bbIntegration = false,
}: TFilialPayData) => {
  const dispatch = useAppDispatch();

  const onOpenDrawerHandler = useCallback(() => {
    dispatch(setShowAppDrawer(true));
    dispatch(
      setAppDrawerData({ title: LABEL_TEXT.yourTariff, description: TOOLTIPS_TEXT.yourTariff }),
    );
  }, [dispatch]);

  const renderDiscount = useMemo(() => {
    return `🛒 Скидка: ${discount}% ${
      bbIntegration && discount === 10 ? '(интеграция с Бьюти Бот)' : ''
    }`;
  }, [discount, bbIntegration]);

  return (
    <div className={className}>
      <div className='flex items-center justify-between'>
        <div className='flex items-center'>
          <h2 className='mr-2 font-bold text-h1_header text-blackText'>Ваш тариф</h2>
          <IconWithDrawer openDrawerHandler={onOpenDrawerHandler} />
        </div>
        <p className='flex items-center font-medium'>
          <Status status={payStatus} title='статус оплаты' />
          <span className='ml-3'>
            {!isAutoPayEnable ? (payStatus ? `Оплачен до ${payTillDate}` : 'Не оплачен') : null}
            {isAutoPayEnable ? `Оплата каждое ${payTillDate.slice(0, 2)} число месяца` : null}
          </span>
        </p>
      </div>
      <p className='text-grayText mb-4'>{`Филиал: ${filialName} №${branch}`}</p>
      <p className='whitespace-pre-wrap break-words mb-4 text-black'>
        {`👩 ${branchEmployees} сотрудников\n💳 ${amount} руб. в месяц\n${renderDiscount}`}
      </p>
    </div>
  );
};
