import { REG_EXP } from '@const/common';
import { payPageEmptyData } from '@data/index';
import { TInitialState } from '@redux/Pay/zod';
import { PayloadAction } from '@reduxjs/toolkit';
import { REQUEST_TEXT_ERROR_STATUS } from '@const/httpConst';
import { TPayData, TPayerData, TPayLinkData } from '@models/Pay';
import { PAY_PAGE_ERROR_MESSAGES, PAY_PAGE_SUCCESS_MESSAGES } from '@const/pay';
import { REJECT_RESPONSE_2_KEY, REJECT_RESPONSE_KEY, TRejectActionPayload } from '@api/types';

export const reducers = {
  setIsAccessOnPayPageDenied(state: TInitialState) {
    state.isAccessOnPayPageDenied = true;
  },
  showConnectAutoPayModal(state: TInitialState) {
    state.isConnectAutoPayModalOpen = true;
  },
  hideConnectAutoPayModal(state: TInitialState) {
    state.isConnectAutoPayModalOpen = false;
  },
  showChangeCardModal(state: TInitialState) {
    state.isChangeModalOpen = true;
  },
  hideChangeCardModal(state: TInitialState) {
    state.isChangeModalOpen = false;
  },
  showAutoPayDisableModal(state: TInitialState) {
    state.isAutoPayDisableModalOpen = true;
  },
  hideAutoPayDisableModal(state: TInitialState) {
    state.isAutoPayDisableModalOpen = false;
  },
  clearPayPageErrors(state: TInitialState) {
    state.isError = false;
  },
  setPayerName(state: TInitialState, action: PayloadAction<string>) {
    state.payerFormData.name = action.payload;
  },
  setPayerInn(state: TInitialState, action: PayloadAction<string>) {
    state.payerFormData.inn = action.payload.replace(REG_EXP.onlyNumbers, '');
  },
  setPayerKpp(state: TInitialState, action: PayloadAction<string>) {
    state.payerFormData.kpp = action.payload.replace(REG_EXP.onlyNumbers, '');
  },
  clearPayToastMessages(state: TInitialState) {
    state.errorToastMessage = '';
    state.successToastMessage = '';
  },
  setPaySuccessToastMessage(state: TInitialState, action: PayloadAction<string>) {
    state.successToastMessage = action.payload;
  },
  setPayErrorToastMessage(state: TInitialState, action: PayloadAction<string>) {
    state.errorToastMessage = action.payload;
  },
  setSelectedCardNumber(
    state: TInitialState,
    action: PayloadAction<{ totalAmount: number; cardNumber: number; monthAmount: number }>,
  ) {
    const { cardNumber, totalAmount, monthAmount } = action.payload;
    state.selectedCardNumber = cardNumber;
    state.selectedCardTotalAmount = totalAmount;
    state.selectedCardMonthAmount = monthAmount;
  },
};

export const getPayPageDataReducer = {
  pending: (state: TInitialState) => {
    state.isLoading = true;
    state.isError = false;
    state.autoPayLink = '';
    state.isFetchingAutoPayLink = false;
    state.isFetchingAutoPayLinkError = false;
    state.isFetchingAutoPayLinkSuccess = false;
    state.changeCardDataLink = '';
    state.changeCardIsLoading = false;
    state.changeCardIsError = false;
    state.changeCardIsSuccess = false;
    state.turnOffAutoPaymentIsLoading = false;
    state.turnOffAutoPaymentIsError = false;
    state.turnOffAutoPaymentIsSuccess = false;
    state.isAccessOnPayPageDenied = false;
    state.isChangeModalOpen = false;
    state.isConnectAutoPayModalOpen = false;
    state.isAutoPayDisableModalOpen = false;
  },
  fulfilled: (state: TInitialState, action: PayloadAction<TPayData | void>) => {
    if (action.payload) {
      state.data = action.payload;
      state.selectedCardTotalAmount = +action.payload.amount;
    } else {
      state.data = payPageEmptyData;
      state.selectedCardTotalAmount = +payPageEmptyData.amount;
    }
    if (state.isRetries) {
      state.retries = 1;
      state.isRetries = false;
    }
    state.isLoading = false;
  },

  rejected: (state: TInitialState, action: PayloadAction<TRejectActionPayload>) => {
    if (action.payload) {
      if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
        const { detail } = action.payload;
        if (detail === REQUEST_TEXT_ERROR_STATUS.TOKEN_EXPIRED && state.retries > 0) {
          state.isRetries = true;
          state.retries -= 1;
        } else {
          state.isError = true;
          state.isLoading = false;
        }
      }
      if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
        const { status } = action.payload;
        if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
          state.isAccessDenied = true;
        } else {
          state.isError = true;
        }
        state.isLoading = false;
      }
    } else {
      state.isError = true;
      state.isLoading = false;
    }
  },
};

export const getPayerDataReducer = {
  pending: (state: TInitialState) => {
    state.isError = false;
    state.isLoading = true;
  },
  fulfilled: (state: TInitialState, action: PayloadAction<TPayerData | void>) => {
    if (action.payload) {
      state.payerData = action.payload;
      state.payerFormData = action.payload;
    }
    state.isLoading = false;
  },
  rejected: (state: TInitialState, action: PayloadAction<TRejectActionPayload>) => {
    if (action.payload) {
      if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
        state.isError = true;
        state.isLoading = false;
      }
      if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
        const { status } = action.payload;
        if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
          state.isAccessOnPayPageDenied = true;
        } else {
          state.isError = true;
        }
        state.isLoading = false;
      }
    }
  },
};

export const setPayerDataReducer = {
  pending: (state: TInitialState) => {
    state.isError = false;
    state.isPayerDataSaving = true;
  },
  fulfilled: (state: TInitialState, action: PayloadAction<TPayerData | void>) => {
    if (action.payload) {
      state.payerData = action.payload;
    }
    state.isPayerDataSaving = false;
    state.successToastMessage = PAY_PAGE_SUCCESS_MESSAGES.SUCCESS_SAVED;
  },
  rejected: (state: TInitialState, action: PayloadAction<TRejectActionPayload>) => {
    state.isPayerDataSaving = false;
    if (action.payload) {
      if (REJECT_RESPONSE_2_KEY.DETAIL in action.payload) {
        state.isError = true;
        state.errorToastMessage = PAY_PAGE_ERROR_MESSAGES.SAVE_ERROR;
      }
      if (REJECT_RESPONSE_KEY.STATUS in action.payload) {
        const { status } = action.payload;
        if (status === REQUEST_TEXT_ERROR_STATUS.ACCESS_DENIED) {
          state.isAccessDenied = true;
        } else {
          state.isError = true;
          state.errorToastMessage = PAY_PAGE_ERROR_MESSAGES.SAVE_ERROR;
        }
        state.isLoading = false;
      }
    } else {
      state.isError = true;
      state.isLoading = false;
    }
  },
};

export const getPayLinkReducer = {
  pending: (state: TInitialState) => {
    state.isGetLinkError = false;
    state.isGetLinkLoading = true;
  },
  fulfilled: (state: TInitialState, action: PayloadAction<TPayLinkData | void>) => {
    state.isGetLinkLoading = false;
    if (action.payload) {
      state.payLink = action.payload;
    }
  },
  rejected: (state: TInitialState) => {
    state.isGetLinkLoading = false;
    state.isGetLinkError = true;
    state.errorToastMessage = PAY_PAGE_ERROR_MESSAGES.GET_LINK_ERROR;
  },
};

export const getInvoiceReducer = {
  pending: (state: TInitialState) => {
    state.isGetInvoiceError = false;
    state.isGetInvoiceSuccess = false;
    state.isGetInvoiceLoading = true;
  },
  fulfilled: (state: TInitialState, action: PayloadAction<TPayLinkData | void>) => {
    state.isGetInvoiceLoading = false;
    state.isGetInvoiceSuccess = !!action.payload?.telegramStatus;
    state.isGetInvoiceError = !state.isGetInvoiceSuccess;

    if (action.payload?.telegramStatus !== null) {
      if (action.payload?.telegramStatus) {
        state.successToastMessage = PAY_PAGE_SUCCESS_MESSAGES.SEND_INVOICE_IN_TG;
      } else {
        state.errorToastMessage = PAY_PAGE_ERROR_MESSAGES.SEND_INVOICE_IN_TG;
      }
    }
  },
  rejected: (state: TInitialState) => {
    state.isGetInvoiceLoading = false;
    state.isGetInvoiceError = true;
    state.isGetInvoiceSuccess = false;
    state.errorToastMessage = PAY_PAGE_ERROR_MESSAGES.GET_INVOICE_ERROR;
  },
};
