import React, { memo, useCallback, useEffect, useMemo } from 'react';
//
import { Button } from '@uikit/Button';
import { Loader } from '@blocks/loader';
import { TSettingsData } from '@models/Settings';
import { getSettingsData } from '@redux/selectors';
import { ErrorMessage } from '@blocks/errorMessage';
import { TextInput } from '@uikit/Inputs/DefaultInput';
import { SETTINGS_DATA_KEY_NAMES } from '@const/settings';
import { ToastsMessages } from '@components/toastsMessages';
import { SettingsCheckbox } from '@blocks/settingsCheckbox';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { IconWithTooltips } from '@components/iconWithTooltips';
import { getSettings, postSettings } from '@redux/Settings/thunk';
import { SettingsInputWithDropdown } from '@blocks/settingsInputWithDropdown';
import { PeriodsOfReturnStatistics } from '@components/periodsOfReturnStatistics';
import {
  TSPNameList,
  TOnChangeHandler,
  TDeleteBalloonSet,
  TSetSelectedPeriodMonth,
} from '@shared/types';
import {
  addBalloonSP,
  clearPostError,
  deleteBalloonSP,
  setParamsCalcStat,
  clearSuccessStatus,
  setCustomerChurnTime,
  setIsChurnTimeAllowEdit,
  changeSettingsInputValue,
  changeSettingsCheckboxChecked,
} from '@redux/Settings/slice';

type TCommonSettingsTemplateProps = {
  selectedFilialAccId: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   */
  className?: string;
};

export const CommonSettingsTemplate = memo(
  ({ selectedFilialAccId, className = '' }: TCommonSettingsTemplateProps) => {
    const dispatch = useAppDispatch();
    const {
      data,
      isError,
      isRefresh,
      isLoading,
      isPosting,
      isRetries,
      isSuccess,
      isPostError,
      errorToastMessage,
      successToastMessage,
      isSaveSettingSuccess,
      isChurnTimeAllowEdit,
      churnTimeAllowEditDelay,
    } = useAppSelector(getSettingsData);

    const { callStats, missedCallAnswer, employeesToIgnore, customerChurnTime, useGroups } = data;

    useEffect(() => {
      let id: NodeJS.Timeout;

      const enableChurnTimeEditHandler = () => {
        dispatch(setIsChurnTimeAllowEdit(true));
      };

      if (churnTimeAllowEditDelay > 0) {
        id = setTimeout(() => enableChurnTimeEditHandler(), churnTimeAllowEditDelay);
      }

      return () => clearTimeout(id);
    }, [churnTimeAllowEditDelay, dispatch]);

    // Запрашивает данные страницы настроек при первом рендеренге и при смене филиала в меню
    useEffect(() => {
      if (selectedFilialAccId) {
        dispatch(getSettings());
      }
    }, [dispatch, selectedFilialAccId]);

    useEffect(() => {
      if (isRefresh) {
        dispatch(getSettings());
      }
    }, [dispatch, isRefresh]);

    // Запрашивает список филиалов повторно при ошибке токена
    useEffect(() => {
      if (isRetries) {
        setTimeout(() => dispatch(getSettings()), 200);
      }
    }, [dispatch, isRetries]);

    // функция для сохранения настроек
    const postSettingHandler = useCallback(
      (newSettings: TSettingsData) => () => {
        dispatch(postSettings(newSettings));
      },
      [dispatch],
    );

    // Обработчик события Change, обрабатывает чекбоксы.
    const onChangeSelectHandler: TOnChangeHandler = useCallback(
      event => {
        const { name, checked } = event.currentTarget;
        dispatch(changeSettingsCheckboxChecked({ name, checked }));
      },
      [dispatch],
    );

    // Удаляет 'шарик' по его значению
    const deleteBalloon: TDeleteBalloonSet = useCallback(
      (value, keyName) => () => {
        const tKeyName = keyName as TSPNameList;
        dispatch(deleteBalloonSP({ keyName: tKeyName, value }));
      },
      [dispatch],
    );

    // Добавляет новый по событию Blur шарик и очищает инпут
    const addBalloonOnBlurHandler: (name: string, value: string) => void = useCallback(
      (name, value) => {
        const keyName = name as TSPNameList;
        dispatch(addBalloonSP({ keyName, value }));
      },
      [dispatch],
    );

    // Проверяем идет ли загрузка данных
    const isFetching = useMemo(() => isPosting || isRefresh, [isPosting, isRefresh]);

    // Проверка формы на возможность сохранения
    const canISaveData = !!customerChurnTime;

    // Скрывает тосты с сообщениями о успехе
    const clearSuccessStatusHandler = useCallback(() => {
      dispatch(clearSuccessStatus());
    }, [dispatch]);

    // Скрывает тосты с сообщениями об ошибке
    const clearPostErrorHandler = useCallback(() => {
      dispatch(clearPostError());
    }, [dispatch]);

    const setSelectedPassedPeriodMonth: TSetSelectedPeriodMonth = month => {
      dispatch(setParamsCalcStat({ name: 'startRate', value: month }));
    };

    const setSelectedCurrentPeriodMonth: TSetSelectedPeriodMonth = month => {
      dispatch(setParamsCalcStat({ name: 'endRate', value: month }));
    };

    // Обработчик события Change, устанавливает значение customerChurnTime
    const setCustomerChurnTimeHandler: TOnChangeHandler = useCallback(
      event => {
        const { value } = event.currentTarget;
        dispatch(setCustomerChurnTime(value));
      },
      [dispatch],
    );

    // очищает инпут по нажатию на кнопку очистки
    const clearInputHandler = useCallback(
      ({ name }: { name: string }) =>
        () => {
          dispatch(changeSettingsInputValue({ name, value: '1' }));
        },
      [dispatch],
    );

    return (
      <div
        className={`${
          isLoading ? `flex flex-col items-center justify-center grow` : 'likeForm'
        } ${className}`}>
        {isError ? <ErrorMessage /> : null}
        {isLoading && !isError ? <Loader /> : null}
        {!isLoading && !isError ? (
          <div className='mb-8 max-w-[45.75rem] w-full'>
            <div className='mb-8'>
              <div className='mb-2'>
                <SettingsCheckbox
                  isShowInfoIcon
                  checked={missedCallAnswer}
                  onChangeHandler={onChangeSelectHandler}
                  htmlFor={SETTINGS_DATA_KEY_NAMES.MISSED_CALL_ANSWER}
                  variant={SETTINGS_DATA_KEY_NAMES.MISSED_CALL_ANSWER}
                />
              </div>
              <div className='mb-2'>
                <SettingsCheckbox
                  isShowInfoIcon
                  checked={callStats}
                  onChangeHandler={onChangeSelectHandler}
                  htmlFor={SETTINGS_DATA_KEY_NAMES.CALL_STATS}
                  variant={SETTINGS_DATA_KEY_NAMES.CALL_STATS}
                />
              </div>
              <SettingsCheckbox
                isShowInfoIcon
                checked={useGroups}
                onChangeHandler={onChangeSelectHandler}
                htmlFor={SETTINGS_DATA_KEY_NAMES.USE_GROUPS}
                variant={SETTINGS_DATA_KEY_NAMES.USE_GROUPS}
              />
            </div>
            <div className='mb-10'>
              <PeriodsOfReturnStatistics
                endRate={data.paramsCalcStats.endRate}
                startRate={data.paramsCalcStats.startRate}
                setSelectedPassedPeriodMonth={setSelectedPassedPeriodMonth}
                setSelectedCurrentPeriodMonth={setSelectedCurrentPeriodMonth}
              />
            </div>
            <div className='mb-8 max-w-[45.75rem] w-full'>
              <div className='flex items-center mb-4'>
                <p className='font-medium text-black text-h3Mobile tracking-[0.0075em]  mr-2'>
                  Показатель оттока от каждого мастера
                </p>
                <IconWithTooltips tooltips='customerChurnTime' className='mb-1' />
              </div>
              <TextInput
                type='text'
                view='outlined'
                value={customerChurnTime}
                error={!customerChurnTime}
                placeholder='Введите значение'
                disabled={!isChurnTimeAllowEdit}
                errorHelperText='Введите значение'
                onChange={setCustomerChurnTimeHandler}
                clearSearchString={clearInputHandler({
                  name: SETTINGS_DATA_KEY_NAMES.CUSTOMER_CHURN_TIME,
                })}
                color={!customerChurnTime ? 'danger' : 'default'}
                clearButton={isChurnTimeAllowEdit && !!customerChurnTime}
                className='mb-8 max-w-40'
              />
            </div>
            <div className='mb-8 max-w-[45.75rem] w-full'>
              <div className='flex items-center mb-4'>
                <p className='flex items-center font-medium text-black text-h3Mobile tracking-[0.0075em] mr-2'>
                  Не считать статистику по сотрудникам
                </p>
                <IconWithTooltips tooltips='noStaffStats' className='mb-1' />
              </div>
              <SettingsInputWithDropdown
                isDanger
                deleteBalloon={deleteBalloon}
                isFetchingSuccess={isSuccess}
                addedValues={employeesToIgnore}
                staffDropdownData={data.staffList}
                selectedFilialAccId={selectedFilialAccId}
                addBalloonOnBlur={addBalloonOnBlurHandler}
                inputId={SETTINGS_DATA_KEY_NAMES.EMPLOYEES_TO_IGNORE}
                labelTextVariant={SETTINGS_DATA_KEY_NAMES.EMPLOYEES_TO_IGNORE}
                className='max-w-[45.75rem]'
              />
            </div>
            <Button
              dense
              type='action'
              view='filled'
              color='success'
              loading={isFetching}
              text='Сохранить настройки'
              onClick={postSettingHandler(data)}
              disabled={isFetching || !canISaveData}
              className='w-fit'
            />
          </div>
        ) : null}
        <ToastsMessages
          successToastTimeout={2000}
          isShowErrorToast={isPostError}
          errorMessage={errorToastMessage}
          successMessage={successToastMessage}
          isShowSuccessToast={isSaveSettingSuccess}
          clearErrorCallback={clearPostErrorHandler}
          clearSuccessStatusCallback={clearSuccessStatusHandler}
        />
      </div>
    );
  },
);
