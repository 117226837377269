import React, { memo } from 'react';
import { useLocation } from 'react-router-dom';
//
import { H1 } from '@atoms/h1';
import { ACCESS_RIGHTS } from '@const/access';
import { PATH_NAMES } from '@const/httpConst';
import { NoAccess } from '@components/noAccess';
import { Telephony } from '@templates/telephony';
import { TemplateNav } from '@blocks/templateNav';
import { AppDrawer } from '@components/appDrawer';
import { settingsPageNameDict } from '@const/settings';
import { BlackListTemplate } from '@templates/blackList';
import { SettingsAdmin } from '@templates/settingsAdmin';
import { SettingsPayTemplate } from '@templates/settingsPay';
import { SettingsMasters } from '@templates/settingsMasters';
import { useAppDispatch, useAppSelector } from '@hooks/index';
import { CommonSettingsTemplate } from '@templates/commonSettings';
import { setAppDrawerData, setShowAppDrawer } from '@redux/App/slice';
import { SettingsNotifications } from '@templates/settingsNotifications';
import { getAccountsData, getAppData, getSettingsData } from '@redux/selectors';

const SettingsPage = memo(() => {
  const { pathname: path } = useLocation();
  const dispatch = useAppDispatch();
  const { selectedFilial } = useAppSelector(getAccountsData);
  const { showAppDrawer } = useAppSelector(getAppData);
  const {
    isRetries,
    tableData,
    isAccessDenied,
    adminsTableData,
    errorToastMessage,
    isGetTableDateError,
    successToastMessage,
    isAdminsTableDataError,
    isAdminsTableDataSaving,
    isAdminsTableDataLoading,
    isAdminsTableDataSuccess,
    isGettingTableDataSuccess,
  } = useAppSelector(getSettingsData);

  // Id выбранного филиала
  const selectedFilialAccId = selectedFilial?.accId || '';

  // Проверяем наличие прав владельца
  const isShowAdminsTab = (selectedFilial?.rights || []).includes(ACCESS_RIGHTS.SETTINGS_ADMINS);

  // Подготавливаем объект для формирования элементов блока навигации
  // Проверяем наличие прав владельца и если их нет удаляем из списка путь к странице админов
  const settingsNavItemsDict = isShowAdminsTab
    ? settingsPageNameDict
    : Object.fromEntries(
        Object.entries(settingsPageNameDict)
          .map(navItem => {
            if (navItem[0] === PATH_NAMES.SETTINGS_ADMINS) {
              return [];
            }
            return navItem;
          })
          .filter(navItem => navItem.length),
      );

  const onCloseDrawerHandler = () => {
    dispatch(setShowAppDrawer(false));
    dispatch(setAppDrawerData({ title: '', description: '' }));
  };

  return (
    <div className='flex flex-col min-h-[100%]'>
      <H1 className='mb-6'>Настройки</H1>
      <TemplateNav
        path={path}
        isDisable={false}
        callBack={() => ''}
        pageNameDict={settingsNavItemsDict}
        selectedFilialAccId={selectedFilialAccId}
        className='mb-2'
      />
      {isAccessDenied ? <NoAccess /> : null}
      {!isAccessDenied ? (
        <>
          {path === PATH_NAMES.SETTINGS ? (
            <CommonSettingsTemplate selectedFilialAccId={selectedFilialAccId} />
          ) : null}
          {path.includes(PATH_NAMES.TELEPHONY) ? <Telephony showCommercial={false} /> : null}
          {path.includes(PATH_NAMES.SETTINGS_ADMINS) ? (
            <SettingsAdmin
              isRetries={isRetries}
              adminsTableData={adminsTableData}
              selectedFilialAccId={selectedFilialAccId}
              isAdminsTableDataError={isAdminsTableDataError}
              isAdminsTableDataLoading={isAdminsTableDataLoading}
              isAdminsTableDataSuccess={isAdminsTableDataSuccess}
              errorToastMessage={errorToastMessage}
              successToastMessage={successToastMessage}
              isAdminsTableDataSaving={isAdminsTableDataSaving}
            />
          ) : null}
          {path.includes(PATH_NAMES.SETTINGS_MASTERS) ? (
            <SettingsMasters
              isRetries={isRetries}
              selectedFilialAccId={selectedFilialAccId}
              isGetTableDateError={isGetTableDateError}
              isGettingTableDataSuccess={isGettingTableDataSuccess}
              tableData={tableData}
            />
          ) : null}
          {path.includes(PATH_NAMES.BLACKLIST) ? <BlackListTemplate /> : null}
          {path.includes(PATH_NAMES.SETTINGS_PAY) ? <SettingsPayTemplate /> : null}
          {path.includes(PATH_NAMES.SETTINGS_NOTIFICATIONS) ? <SettingsNotifications /> : null}
        </>
      ) : null}
      <AppDrawer showAppDrawer={showAppDrawer} onCloseHandler={onCloseDrawerHandler} />
    </div>
  );
});

export default SettingsPage;
