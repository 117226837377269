import React from 'react';
import { createPortal } from 'react-dom';
//
import { Overlay } from '@atoms/overlay';
import { Icon } from '@blueprintjs/core';
import { useWindowWidth } from '@hooks/useWindowWidth';

type TVideoInstructionModalProps = {
  /**
   * Флаг открытия модального окна
   * @param {boolean}
   */
  isOpen: boolean;
  /**
   * Callback для закрытия модалки
   * @param {() => void}
   */
  closeModalHandler: () => void;
  /**
   * Линк на видео размещенное на YouTube
   * @param {string}
   */
  videoLink: string;
  /**
   * Текст в заголовок
   * @param{string}
   */
  headerText: string;
  /**
   * Время начала проигрывания
   * @param {string}
   */
  startTime?: string;
  /**
   * Опциональный параметр строка классов
   * @param {string}
   * @default
   */
  className?: string;
};

export const VideoInstructionModal = ({
  isOpen,
  videoLink,
  headerText,
  startTime = '1',
  closeModalHandler,
  className = '',
}: TVideoInstructionModalProps) => {
  const width = useWindowWidth();

  const link = `${videoLink};start=${startTime}`;

  return (
    <div>
      {isOpen
        ? createPortal(
            <div
              className={`absolute top-0 right-0 bottom-0 left-0 flex items-start justify-center ${className}`}>
              <div
                className={`bg-black flex flex-col ${
                  width > 980
                    ? 'rounded-[1.5rem] max-w-[74.6875rem] grow basis-[50.875rem]'
                    : 'grow'
                } ${
                  width > 640 ? 'rounded-[1.5rem] p-10 mt-[8.3125rem]' : 'p-4 h-[100vh]'
                } w-full z-[55] shadow-videoInstructionModalShadow`}>
                <div className='flex items-center justify-between mb-6'>
                  <p className='font-bold text-white text-h1_header leading-9'>{headerText}</p>
                  <button
                    aria-label='closeModal'
                    onClick={closeModalHandler}
                    className='bg-none border-none p-2'>
                    <Icon icon='cross' color='#787885' size={24} />
                  </button>
                </div>
                <iframe
                  src={link}
                  allowFullScreen
                  frameBorder='0'
                  title='YouTube video player'
                  referrerPolicy='strict-origin-when-cross-origin'
                  allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
                  className='aspect-video w-full'
                />
              </div>
              <Overlay onClickCallback={closeModalHandler} className='bg-[#19191D66] z-[54]' />
            </div>,
            document.body,
          )
        : null}
    </div>
  );
};
